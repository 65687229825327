import {
  useGetVeicoliQuery,
  useLazyGetOptionalsQuery
} from "../../../features/api/apiSlice";
import { Select } from "@mantine/core";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  selectEngine,
  selectListino,
  selectModel,
  selectVeicolo,
  setOptions,
  setVeicolo,
} from "../../../features/preventivo/preventivoSlice";
import { optionsFilter } from "../../../utils/utils";
import { EOptionType } from "../../../queries/veicoli";

const SelectVeicolo = () => {
  const dispatch = useAppDispatch();
  const model = useAppSelector(selectModel);
  const listino = useAppSelector(selectListino);
  const engine = useAppSelector(selectEngine);
  const disabled = !model || !listino || !engine;
  const { data } = useGetVeicoliQuery(
    { model: model, listino, engine },
    { skip: disabled },
  );

  const currentValue = useAppSelector(selectVeicolo);
  const [getOptions, { data: optionsData }] = useLazyGetOptionalsQuery();

  const updateVeicolo = async (veicolo: any) => {
    const options = await getOptions({
      limit: 300,
      veicolo: veicolo?.id || "",
      listino: listino || "",
    }).unwrap();


    const servizi = options.optionals.filter(({ cm_type }) => cm_type === "S");
    const serviziObbligatori = servizi.filter(
      ({ obbligatorio }) => obbligatorio === "true",
    );

    dispatch(setVeicolo({
      ...veicolo,
    }));

    dispatch(
      setOptions({
        options: serviziObbligatori || [],
        type: EOptionType.SERVIZI,
      }),
    );
  };

  return (
    <>
      <Select
        label="Versione"
        placeholder="Seleziona una versione"
        data={
          data?.veicolis?.map((veicolo: any) => {
            return {
              value: veicolo.id,
              label: `${veicolo.id} - ${veicolo.description}`,
            };
          }) || []
        }
        value={currentValue?.id ? currentValue.id : null}
        disabled={disabled}
        searchable
        nothingFoundMessage="Nessuna versione trovata..."
        className="mb-5"
        onChange={(sel) => {
          const selectedVeicolo = data?.veicolis?.find(({ id }) => id === sel);
          selectedVeicolo && updateVeicolo(selectedVeicolo);
        }}
        filter={optionsFilter}
        radius={12}
        styles={{ input: { height: 44 } }}
      />
    </>
  );
};

export default SelectVeicolo;
