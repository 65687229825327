import { Button, Container } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import NotificationsTable from "../../../components/Table/NotificationsTable";
import { modals } from "@mantine/modals";
import NotificationForm from "../../../components/Form/NotificationForm";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { User } from "../../../features/users/UsersSlice";
import { useParams } from "react-router-dom";

const NotificheCliente = () => {
  const authUser: User | null = useAuthUser();
  const { customerId } = useParams();

  return (
    <>
      <section className="px-10 py-5">
        <div className="flex gap-5">
          <Button
            variant="outline"
            radius="xl"
            leftSection={<IconAlertCircle size={18} />}
            onClick={() => {
              modals.open({
                padding: 0,
                withCloseButton: false,
                size: "70%",
                radius: 26,
                centered: true,
                children: (
                  <NotificationForm
                    customerId={customerId}
                    authUser={authUser}
                  />
                ),
              });
            }}
          >
            Nuova notifica
          </Button>
        </div>
      </section>
      <Container
        size="100%"
        className="mx-10 rounded-xl bg-transparent p-0 shadow-md"
      >
        <NotificationsTable />
      </Container>
    </>
  );
};

export default NotificheCliente;
