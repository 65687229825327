import SelectTemperatura from "../PreventivoInputs/SelectTemperatura"
import { Tooltip } from "@mantine/core"

const CustomerVehicleBox = ({
  customerName,
  veicoloName,
}: {
  customerName: string | undefined
  veicoloName: string | undefined
}) => {
  return (
    <div className="flex items-center rounded-xl bg-slate-500 text-white h-14 py-2 px-1 shadow-md w-1/2">
      <div className="flex items-center gap-1 w-full">
        <div className="py-1 pl-2 overflow-hidden text-ellipsis text-nowrap">
          <Tooltip label={customerName}>
            <span
              className={`block text-[12px] font-medium ${customerName ? "border-r pr-2" : ""}`}
            >
              {customerName}
            </span>
          </Tooltip>
        </div>
        <div className="py-1 px-2 overflow-hidden text-ellipsis text-nowrap">
          <Tooltip label={veicoloName}>
            <span className="block text-[12px]">
              {veicoloName}
            </span>
          </Tooltip>
        </div>
        <div className="py-1 px-2 w-32 ml-auto flex-none">
          <SelectTemperatura />
        </div>
      </div>
    </div>
  )
}

export default CustomerVehicleBox
