import { useLocation, useParams } from "react-router-dom";
import CustomerNavigation from "../../components/Customer/CustomerNavigation";
import useGetCurrentCustomer from "../../hooks/useGetCurrentCustomer";
import NotificationNavigation from "../../components/Notification/NotificationNavigation/NotificationNavigation";
import AdminNavigation from "../../components/Admin/AdminNavigation";

const Header = ({ children }: { children?: React.ReactNode }) => {
  const location = useLocation();
  const { customerId } = useParams();
  const { currentCustomer } = useGetCurrentCustomer({ customerId });
  const isNewPreventivoLocation = location.pathname === "/preventivi/nuovo";
  const isAdminLocation = location.pathname.match("/admin/");
  const isNotificationLocation =
    location.pathname === "/notifiche" ||
    location.pathname === "/storico-notifiche";
  const headerClasses =
    customerId || isNotificationLocation || isAdminLocation
      ? "min-h-20"
      : "h-20";
  const headerTitleClasses = customerId ? "pt-4" : "h-20";

  let pageTitle: string;
  if (isNewPreventivoLocation) {
    pageTitle = "Nuovo preventivo";
  } else if (location.pathname.match("/clienti/")) {
    pageTitle = currentCustomer?.nome || "Scheda cliente";
  } else if (isAdminLocation) {
    pageTitle = location.pathname
      .replace("admin", "gestione")
      .replace(/[\/-]/g, " ");
  } else {
    pageTitle = location.pathname.replace(/[\/-]/g, " ");
  }

  if (location.pathname === "/") {
    return;
  }

  return (
    <header
      className={`mx-10 mt-3 items-center justify-center rounded-xl bg-white px-8 shadow-md ${headerClasses}`}
    >
      <div className={`flex items-center justify-center ${headerTitleClasses}`}>
        <h1 className="text-lg font-bold uppercase">{pageTitle}</h1>
      </div>
      {customerId && <CustomerNavigation customerId={customerId} />}
      {isNotificationLocation && <NotificationNavigation />}
      {isAdminLocation && <AdminNavigation />}
      {children}
    </header>
  );
};

export default Header;
