import { IconChevronDown } from "@tabler/icons-react"
import { useState } from "react"

interface SummaryBoxProps {
  label: string
  totalItems?: number
  children?: React.ReactNode
  // totalPrice?: number;
  // totalDiscount?: number;
  // withoutPriceCol?: boolean;
  withToggle?: boolean
}

const SummaryBox = ({
  label,
  totalItems,
  children,
  // totalPrice,
  withToggle,
}: SummaryBoxProps) => {
  const [collapsed, setIsCollapsed] = useState(false)

  const handleToggleClick = () => {
    withToggle && setIsCollapsed((prevState) => !prevState)
  }

  // const withOrWithoutTotalPriceClasses = !withoutPriceCol
  //   ? "w-1/2"
  //   : "w-3/4 rounded-r-xl";

  return (
    <div className="summary mb-7 flex rounded-xl border bg-white shadow-md">
      <div className="flex w-1/4 flex-wrap items-start gap-x-3 gap-y-1 rounded-l-xl px-5 py-5">
        <div className="flex items-center gap-1" onClick={handleToggleClick}>
          {withToggle && (
            <IconChevronDown
              className={`h-5 w-5 text-slate-400 ${!collapsed ? "rotate-180" : ""}`}
            />
          )}
          <span className="block font-semibold text-white w-full bg-slate-500 rounded-md px-2">{label}</span>
        </div>
        {totalItems && <span className="text-sm">N° totale: {totalItems}</span>}
      </div>
      <div
        className={`summary__children px-11 py-5 w-3/4 ${collapsed ? "summary__children--collapsed" : ""}`}
      >
        {children}
      </div>
      {/* {!withoutPriceCol && (
        <div className="flex w-1/4 items-center rounded-xl bg-slate-700 px-6 py-5 text-white">
          <div className="flex flex-col items-start">
            <span className="text-sm font-semibold">TOT.</span>
            <span className="my-1 rounded-lg bg-slate-600 p-1 text-lg font-semibold">
              <NumberFormatter
                value={totalPrice?.toFixed(2) || 0}
                decimalScale={2}
                decimalSeparator=","
                thousandSeparator="."
              />
              &nbsp;€
            </span>

            {totalDiscount ? (
              <span className="text-sm font-medium">
                -
                <NumberFormatter
                  value={totalDiscount?.toFixed(2)}
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                />
                &nbsp;€
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>
      )} */}
    </div>
  )
}

export default SummaryBox
