import { Button, Container, Loader } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import useGetCurrentCustomer from "../../../hooks/useGetCurrentCustomer";
import { useNavigate, useParams } from "react-router-dom";
import CustomerData from "./CustomerData/CustomerData";
import CustomerForm from "../../../components/Form/CustomerForm";
import { modals } from "@mantine/modals";
import {
  deleteModalProps,
  onModalDelete,
} from "../../../components/Modal/ModalDelete/ModalDelete";
import { useDeleteAnagraficheMutation } from "../../../features/api/apiSlice";
import useAuthUserData from "../../../hooks/useAuthUserData";

const AnagraficaCliente = () => {
  const { customerId } = useParams();
  const { authUserName } = useAuthUserData();
  const { isLoading, currentCustomer } = useGetCurrentCustomer({ customerId });
  const navigate = useNavigate();
  const [deleteAnagrafiche] = useDeleteAnagraficheMutation();

  const handleEdit = () => {
    modals.open({
      padding: 0,
      withCloseButton: false,
      size: "70%",
      radius: 26,
      children: (
        <CustomerForm
          currentUserName={authUserName}
          currentModalRecord={currentCustomer}
          onCloseClick={() => modals.closeAll()}
        />
      ),
    });
  };
  const handleRemove = () => {
    modals.openConfirmModal({
      ...deleteModalProps("cliente", "clienti", [customerId] as string[]),
      padding: "24px 48px !important",

      onConfirm: () =>
        onModalDelete({
          selectedIds: [customerId] as string[],
          deleteFunction: deleteAnagrafiche,
          singularElement: "cliente",
          pluralElement: "clienti",
          navigate: navigate,
          isIdFromParams: true,
        }),
    });
  };
  return (
    <>
      <Container size="100%" px="40" py="20">
        <div className="flex gap-5">
          <Button
            variant="outline"
            radius="xl"
            leftSection={<IconEdit size={18} />}
            onClick={handleEdit}
          >
            Modifica scheda
          </Button>
          <Button
            color="pink"
            radius="xl"
            leftSection={<IconTrash size={18} />}
            onClick={handleRemove}
          >
            Elimina
          </Button>
        </div>
      </Container>
      <Container
        size="100%"
        bg="white"
        px="40"
        py="20"
        className="relative min-h-96 rounded-xl shadow-md mx-10"
      >
        {isLoading ? (
          <Loader
            color="blue"
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
            type="dots"
          />
        ) : (
          currentCustomer && <CustomerData currentCustomer={currentCustomer} />
        )}
      </Container>
    </>
  );
};

export default AnagraficaCliente;
