import { Link, RichTextEditor } from "@mantine/tiptap"
import { useEditor } from "@tiptap/react"
import Highlight from "@tiptap/extension-highlight"
import StarterKit from "@tiptap/starter-kit"
import Underline from "@tiptap/extension-underline"
import TextAlign from "@tiptap/extension-text-align"
import {
  decodePreventivoString,
  encodePreventivoString,
} from "../../../../../utils/utils"
import { useAppDispatch } from "../../../../../app/hooks"
import { UnknownAction } from "@reduxjs/toolkit"

export interface IPreventivoEditorProps {
  field?: string
  callback: (arg0: string) => UnknownAction
  label?: string
  hasToolbar?: boolean
}

const PreventivoEditor = ({
  field,
  callback,
  hasToolbar = true,
}: IPreventivoEditorProps) => {
  const dispatch = useAppDispatch()
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
    ],
    content: decodePreventivoString(field || ""),
    onUpdate: ({ editor }) => {
      dispatch(callback(encodePreventivoString(editor.getHTML())))
    },
  })

  return (
    <RichTextEditor editor={editor}>
      {hasToolbar && (
        <RichTextEditor.Toolbar sticky stickyOffset={60}>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            {/* <RichTextEditor.Underline />
      <RichTextEditor.Strikethrough />
      <RichTextEditor.Highlight /> */}
          </RichTextEditor.ControlsGroup>

          {/* <RichTextEditor.ControlsGroup>
      <RichTextEditor.H1 />
      <RichTextEditor.H2 />
      <RichTextEditor.H3 />
      <RichTextEditor.H4 />
    </RichTextEditor.ControlsGroup> */}

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.BulletList />
          </RichTextEditor.ControlsGroup>

          {/* 
    <RichTextEditor.ControlsGroup>
      <RichTextEditor.AlignLeft />
      <RichTextEditor.AlignCenter />
      <RichTextEditor.AlignJustify />
      <RichTextEditor.AlignRight />
    </RichTextEditor.ControlsGroup> */}

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Undo />
            <RichTextEditor.Redo />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>
      )}

      <RichTextEditor.Content />
    </RichTextEditor>
  )
}

export default PreventivoEditor
