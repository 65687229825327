import { Button, Container } from "@mantine/core";
import PreventiviTable from "../../components/Table/PreventiviTable";
import { IconFilePlus } from "@tabler/icons-react";
import { Link } from "react-router-dom";

const Preventivi = () => {
  return (
    <>
      <Container size="100%" px="40" py="20">
        <Link to="/preventivi/nuovo">
          <Button
            variant="outline"
            radius="xl"
            leftSection={<IconFilePlus size={18} />}
          >
            Nuovo preventivo
          </Button>
        </Link>
      </Container>
      <Container
        size="100%"
        className="mx-10 rounded-xl bg-transparent p-0 shadow-md"
      >
        <PreventiviTable />
      </Container>
    </>
  );
};

export default Preventivi;
