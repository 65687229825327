import { gql } from "@apollo/client";
import { BSON } from "realm-web";

export interface Anagrafica {
  _id: string;
  idAnagrafica: string;
  codMaster: string;
  nome: string;
  cf: string;
  piva: string;
  tipologia: "P" | "A";
  indirizzo: string;
  civico: string;
  cap: string;
  localita: string;
  prov: string;
  valuta: "EUR";
  nazione: "IT";
  tel: string;
  email: string;
  pec: string;
  note?: string;
  referenti?: Array<string>;
  cortese_attenzione?: string;
}

export interface GetAnagraficheResponse {
  anagraficas: Anagrafica[];
}

export interface AnagraficaResponse {
  data: {
    anagrafica: Anagrafica;
  };
}

export interface AnagraficaQuery {
  limit?: number;
  lastItemId?: string;
  dir?: "g" | "l";
}

export const queryAnagrafiche = ({
  limit,
  lastItemId,
  dir,
}: AnagraficaQuery) => ({
  document: gql`
    query {
      anagraficas (limit: ${limit}, sortBy: _ID_DESC) {
        _id
        cap
        cf
        civico
        cod_master
        email
        id_anagrafica
        indirizzo
        localita
        nazione
        nome
        pec
        piva
        prov
        tipologia
        valuta
      }
    }
  `,
});

export const mutationAnagrafica = (anagrafica: Anagrafica) => ({
  document: gql`
    mutation {
      upsertOneAnagrafica (
        data: {
          nome: "${anagrafica.nome}",
          email: "${anagrafica.email}",
          cap: "${anagrafica.cap}",
          cf: "${anagrafica.cf}",
          civico: "${anagrafica.civico}",
          indirizzo: "${anagrafica.indirizzo}",
          localita: "${anagrafica.localita}",
          nazione: "${anagrafica.nazione}",
          pec: "${anagrafica.pec}",
          piva: "${anagrafica.piva}",
          prov: "${anagrafica.prov}",
          tipologia: "${anagrafica.tipologia}",
          valuta: "${anagrafica.valuta}",
        },
        query: {
          _id: "${new BSON.ObjectID(anagrafica._id)}"
        }
      ) {email, _id, cf}
    }
  `,
});

export const deleteAnagrafiche = (ids: string[]) => ({
  document: gql`
    mutation {
      deleteManyAnagraficas (
        query: {
          _id_in: [${ids.map((id) => `"${new BSON.ObjectID(id)}"`).join(",")}]
        }
      ) {deletedCount}
    } 
  `,
});
