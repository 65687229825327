import { NavLink } from "react-router-dom";

const AdminNavigation = () => {
  const adminNavigation = [
    { label: "Gestione accessori", path: `/admin/accessori` },
    { label: "Gestione utenti", path: `/admin/utenti` },
  ];

  const adminsNavigationClasses = {
    active:
      "relative text-sm text-slate-900 font-semibold pb-2 before:w-[calc(100%+12px)] before:h-[3px] before:bg-slate-900 before:absolute before:-left-[6px] before:bottom-0 before:rounded-full",
    idle: "text-sm text-slate-600 pb-2",
  };

  return (
    <div className="flex justify-center gap-20 pt-4">
      {adminNavigation.map((navigation) => (
        <NavLink
          key={navigation.label}
          to={navigation.path}
          className={({ isActive }) =>
            isActive
              ? adminsNavigationClasses.active
              : adminsNavigationClasses.idle
          }
        >
          {navigation.label}
        </NavLink>
      ))}
    </div>
  );
};

export default AdminNavigation;
