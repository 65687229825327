import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import useAuthUser from "react-auth-kit/hooks/useAuthUser"
import {
  selectAccessori,
  selectCliente,
  selectEngine,
  selectListino,
  selectModel,
  selectOptionals,
  selectPermute,
  selectSconti,
  selectPriceFinal,
  selectScontoGrandTotal,
  selectServizi,
  selectTemperatura,
  selectVeicolo,
  setInitialPreventivo,
  selectAgente,
  selectOptionalsValues,
  selectAccessoriValues,
  selectServiziValues,
  selectFirstSavedAt,
  selectModello,
  selectOggetto,
  selectMotore,
  selectDimensioni,
  selectDotazioniGuida,
  selectDotazioniInterne,
  selectDotazioniSicurezza,
  selectDotazioniTecnologia,
  selectCaratteristicheVersione,
} from "../features/preventivo/preventivoSlice"
import {
  useLazyGetPreventivoQuery,
  useGetNotificationsQuery,
} from "../features/api/apiSlice"
import { useLocation } from "react-router-dom"
import { TS4Save } from "../utils/formatters"

const usePreventivoData = ({
  preventivoId,
}: {
  preventivoId: string | undefined
}) => {
  const dispatch = useAppDispatch()
  const authUser = useAuthUser()
  const temperatura = useAppSelector(selectTemperatura)
  const model = useAppSelector(selectModel)
  const modello = useAppSelector(selectModello)
  const oggetto = useAppSelector(selectOggetto)
  const motore = useAppSelector(selectMotore)
  const dimensioni = useAppSelector(selectDimensioni)
  const dotazioni_guida = useAppSelector(selectDotazioniGuida)
  const dotazioni_interne = useAppSelector(selectDotazioniInterne)
  const dotazioni_sicurezza = useAppSelector(selectDotazioniSicurezza)
  const dotazioni_tecnologia = useAppSelector(selectDotazioniTecnologia)
  const caratteristiche_versione = useAppSelector(selectCaratteristicheVersione)
  const currentCliente = useAppSelector(selectCliente)
  const agente = useAppSelector(selectAgente)
  const optionals = useAppSelector(selectOptionals)
  const accessori = useAppSelector(selectAccessori)
  const servizi = useAppSelector(selectServizi)
  const permute = useAppSelector(selectPermute)
  const listino = useAppSelector(selectListino)
  const engine = useAppSelector(selectEngine)
  const veicolo = useAppSelector(selectVeicolo)
  const priceFinal = useAppSelector(selectPriceFinal)
  const preventivo = useAppSelector((state) => state.preventivo)
  const permuteTotal = permute.reduce((acc, { price }) => acc + price, 0)
  const listinoPrice = veicolo?.price || 0
  const scontoGrandTotal = useAppSelector(selectScontoGrandTotal)
  const sconti = useAppSelector(selectSconti)
  const location = useLocation()
  const firstSavedAt = useAppSelector(selectFirstSavedAt)

  const optionalTotal = useAppSelector(selectOptionalsValues)
  const accessoriTotal = useAppSelector(selectAccessoriValues)
  const serviziTotal = useAppSelector(selectServiziValues)

  // @ts-ignore
  const { _id: id_agente } = authUser
  const [getPreventivo, { data, isLoading }] = useLazyGetPreventivoQuery()
  const { data: dataNotifications } = useGetNotificationsQuery(
    {
      id_agente,
      id_preventivo: preventivoId || "0000",
    },
    {
      pollingInterval: 300e3,
      skipPollingIfUnfocused: true,
    },
  )

  useEffect(() => {
    const fetchPreventivo = async () => {
      try {
        const preventivoQuery = await getPreventivo({
          id: preventivoId || "0",
        }).unwrap()
        if (location.pathname === `/preventivi/duplica/${preventivoId}`) {
          const initialPreventivo = {
            ...preventivoQuery.preventivi,
            started_at: TS4Save(Date.now()),
            first_saved_at: 0,
            last_modified_at: 0,
            last_printed_at: 0,
            last_sent_at: 0,
            front_date: 0,
            _id: undefined,
          }
          dispatch(setInitialPreventivo(initialPreventivo))
        } else {
          dispatch(setInitialPreventivo(preventivoQuery.preventivi))
        }
      } catch (error) {
        console.error("Error fetching preventivo data:", error)
      }
    }

    if (preventivoId) {
      fetchPreventivo()
    }
  }, [preventivoId, dispatch, getPreventivo])

  return {
    currentCliente,
    agente,
    temperatura,
    model,
    modello,
    oggetto,
    motore,
    dimensioni,
    dotazioni_guida,
    dotazioni_interne,
    dotazioni_sicurezza,
    dotazioni_tecnologia,
    caratteristiche_versione,
    listino,
    optionals,
    priceFinal,
    preventivo,
    permuteTotal,
    permute,
    listinoPrice,
    servizi,
    accessori,
    engine,
    veicolo,
    data,
    isLoading,
    scontoGrandTotal,
    sconti,
    dataNotifications,
    optionalTotal,
    accessoriTotal,
    serviziTotal,
    firstSavedAt,
  }
}

export default usePreventivoData
