import { MultiSelect } from "@mantine/core";
import { useGetOptionalsQuery } from "../../../features/api/apiSlice";
import {
  selectListino,
  selectOptions,
  selectVeicolo,
  setOptions,
} from "../../../features/preventivo/preventivoSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import OptionsSummary from "../Summaries/OptionsSummary";
import { EOptionType } from "../../../queries/veicoli";
import { optionsFilter } from "../../../utils/utils";

interface ComponentProps {
  type: EOptionType;
  selectLabel?: string;
  summaryLabel?: string;
  summaryPrice?: number;
}

const OptionSelectComponent = ({
  type,
  selectLabel,
  summaryLabel,
  summaryPrice,
}: ComponentProps) => {
  const veicolo = useAppSelector(selectVeicolo);
  const listino = useAppSelector(selectListino);
  const disabled = !veicolo || !listino;

  const { data, isLoading } = useGetOptionalsQuery({
    limit: 300,
    veicolo: veicolo?.id || "",
    listino: listino || "",
  });

  const dispatch = useAppDispatch();
  const currentOptions = useAppSelector(selectOptions);
  const currentItems = currentOptions.filter(({ cm_type }) => cm_type === type);
  //const obbligatori = currentItems.filter(({ obbligatorio }) => obbligatorio);

  return (
    <>
      {/*obbligatori && obbligatori.length > 0 && (
        <div>
          <h3>
            <strong>Servizi iclusi</strong>
          </h3>
          {//obbligatori.map(({ price, label }) => (
            <li>
              {price}, {label}
            </li>
          ))}
        </div>
          )*/}

      <MultiSelect
        comboboxProps={{ dropdownPadding: "22px 24px", withinPortal: false }}
        styles={{
          input: {
            minHeight: 44,
          },
          pillsList: {
            height: "100%",
          },
          option: {
            background: "#FF000",
          },
        }}
        className={"preventivo-optionals__select"}
        label={selectLabel}
        placeholder=""
        data={data?.optionals
          ?.filter(
            ({ cm_type, obbligatorio }) =>
              cm_type === type && obbligatorio !== "true",
          )
          .map(({ label, id, price }) => ({
            value: id,
            label: `${label} (${price} €)`,
          }))}
        maxDropdownHeight={455}
        onChange={(sel) => {
          const options = sel.map((opt) =>
            data?.optionals.find(({ id }) => id === opt),
          );
          //@ts-ignore
          options && dispatch(setOptions({ options, type }));
        }}
        value={currentItems
          .filter(({ obbligatorio }) => obbligatorio !== "true")
          .map(({ id }) => id)}
        searchable
        filter={optionsFilter}
        nothingFoundMessage="..."
        disabled={disabled}
        mb={20}
        radius={12}
      />
      <OptionsSummary
        type={type}
        items={currentItems}
        label={summaryLabel || ""}
      />
    </>
  );
};

export default OptionSelectComponent;
