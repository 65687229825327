const SummaryVerticalBox = ({
  children,
  label,
}: {
  label: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="rounded-xl shadow-md border bg-white">
      <div className="flex h-14 items-center justify-center rounded-t-xl px-5">
        <span className="block font-semibold text-black">{label}</span>
      </div>
      <div className="h-16 px-5 py-4 mb-2">
        {children}
      </div>
    </div>
  );
};

export default SummaryVerticalBox;
