import { useState } from "react"
import { useAppSelector } from "../../../../app/hooks"
import { Button } from "@mantine/core"
import { IconChevronDown } from "@tabler/icons-react"
import { DetailRowObject } from "./DetailRow/DetailRowTypes"
import {
  selectAccessoriValues,
  selectAnticipo,
  selectFerroValues,
  selectMargine,
  selectOptionalsValues,
  selectPriceFinal,
  selectServiziValues,
  selectTotalBeforeDiscount,
  selectTotalPermute,
  selectTotalVariations,
} from "../../../../features/preventivo/preventivoSlice"
import DetailRow from "./DetailRow/DetailRow"

const TotalDetails = () => {
  const [showDetailPanel, setShowDetailPanel] = useState(false)
  const totalBeforeDiscount = useAppSelector(selectTotalBeforeDiscount)
  const totalVariations = useAppSelector(selectTotalVariations)
  const priceFinal = useAppSelector(selectPriceFinal)
  const permute = useAppSelector(selectTotalPermute)
  const margine = useAppSelector(selectMargine)
  const anticipo = useAppSelector(selectAnticipo)
  const ferroTotal = useAppSelector(selectFerroValues)
  const optionalTotal = useAppSelector(selectOptionalsValues)
  const accessoriTotal = useAppSelector(selectAccessoriValues)
  const serviziTotal = useAppSelector(selectServiziValues)

  const handleClick = () => {
    setShowDetailPanel((prevState) => !prevState)
  }

  const detailRows: Array<DetailRowObject> = Array.from([
    {
      label: "Veicolo Base",
      price: ferroTotal[0],
    },
    {
      label: "Optional",
      price: optionalTotal[0],
    },
    {
      label: "Accessori",
      price: accessoriTotal[0],
      rowClass: "pb-3 mb-3 border-b",
    },
    {
      label: "Totale Listino",
      price: totalBeforeDiscount,
    },
    {
      label: "Sconti",
      price: totalVariations,
      isNegative: true,
    },
    {
      label: "Servizi",
      price: serviziTotal[0],
      rowClass: "pb-3 mb-3 border-b",
    },
    {
      label: "Prezzo finale",
      price: priceFinal,
      priceClass: "text-base",
    },
    /*
    {
      label: "IVA",
      // TODO: Portare nello slice con selector
      price: ivaAmount,
      rowClass: "pb-3 mb-3 border-b border-b-white",
    },
    */
    {
      label: "Permute",
      price: permute,
    },
    {
      label: "Anticipo",
      // TODO: Portare nello slice con selector
      price: +(anticipo || 0),
      rowClass: "pb-3 mb-3 border-b",
    },
    {
      label: "Saldo",
      // TODO: Portare nello slice con selector
      price: priceFinal - permute - +(anticipo || 0),
    },
  ])
  return (
    <div className="z-10 px-6 py-4 text-slate-600 bg-white border rounded-xl shadow-md">
      <span className="text-sm font-semibold uppercase mb-2 block">Dettagli prezzo</span>
      {detailRows.map((detail: DetailRowObject, index: number) => (
        <DetailRow
          key={index}
          label={detail.label}
          price={detail.price}
          rowClass={detail.rowClass}
          priceClass={detail.priceClass}
          isNegative={detail.isNegative}
        />
      ))}
      {showDetailPanel && (
        <DetailRow
          key={"margine"}
          label="Margine"
          price={margine}
          priceClass="bg-cm-green text-white"
        />
      )}
      <div className="flex justify-end">
        <Button
          variant="transparent"
          p={0}
          h={16}
          className="uppercase text-[9px] text-slate-600"
          onClick={handleClick}
          rightSection={
            <IconChevronDown
              className={`w-4 h-4 ${showDetailPanel ? "rotate-180" : ""}`}
            />
          }
        >
          Dettagli
        </Button>
      </div>
    </div>
  )
}

export default TotalDetails
