import PreventivoSteps from "../../../../components/Preventivo/PreventivoSteps/PreventivoSteps";
import { Flex, Switch } from "@mantine/core";
import { Anagrafica } from "../../../../queries/anagrafica";
import { Veicolo } from "../../../../queries/veicoli";
import CustomerVehicleBox from "../../../../components/Preventivo/CustomerVehicleBox/CustomerVehicleBox";
import SelectIva from "../../../../components/Preventivo/PreventivoInputs/SelectIva";
import TotalBox from "../../../../components/Preventivo/TotalBox/TotalBox";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectIvaShown, setIvaShown } from "../../../../features/ui/uiSlice";

interface ITopActionsProps {
  preventivoId: string;
  currentCliente: Anagrafica | undefined;
  veicolo: Veicolo | undefined;
}

const TopActions = ({
  preventivoId,
  currentCliente,
  veicolo,
}: ITopActionsProps) => {
  const dispatch = useAppDispatch();
  const isIvaShown = useAppSelector(selectIvaShown);
  const handleShowIvaChange = () => {
    dispatch(setIvaShown());
  };

  return (
    <div className="sticky top-2 z-30">
      <PreventivoSteps preventivoId={preventivoId} />
      <Flex
        mb={16}
        justify={"space-between"}
        className="progress__header px-10 py-5"
        gap={32}
      >
        <CustomerVehicleBox
          customerName={currentCliente?.nome}
          veicoloName={veicolo?.description}
        />
        <div className="flex items-center gap-3">
          <Switch size="md" onChange={handleShowIvaChange} />
          <SelectIva />
          <TotalBox />
        </div>
      </Flex>
    </div>
  );
};

export default TopActions;
