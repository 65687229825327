import { Outlet } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { selectIsSidebarCollapsed } from "../../features/ui/uiSlice";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import { useApp } from "../../components/RealmApp";

const RootLayout = () => {
  const isSidebarCollapsed = useAppSelector(selectIsSidebarCollapsed);
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const app = useApp();

  useEffect(() => {
    if (location.pathname === "/") {
      if (!isAuthenticated()) {
        navigate("/login");
      }
    }
  }, []);

  const mainWidth = isSidebarCollapsed
    ? "w-[calc(100%-84px)] ml-[84px]"
    : "w-[82%] ml-[18%]";

  return (
    <>
      {/* Main App Layout  */}
      <Sidebar />
      <main className={`${mainWidth} min-h-[100vh-8px] bg-[#F2F2F2]`}>
        <Header />
        <Outlet />
      </main>
    </>
  );
};

export default RootLayout;
