import OptionSelectComponent from "../../../../components/Preventivo/PreventivoInputs/SelectOptions";
import { EOptionType } from "../../../../queries/veicoli";

const Step2 = () => {
  return (
    <div className="px-6 pt-8 py-4 shadow-md rounded-xl bg-white border">
      <div className="relative mb-8 w-full">
        <OptionSelectComponent
          type={EOptionType.OPTIONALS}
          selectLabel="Seleziona gli optionals"
          summaryLabel="Riepilogo optionals"
        />
      </div>
      <div className="relative mb-8 w-full">
        <OptionSelectComponent
          type={EOptionType.ACCESSORI}
          selectLabel="Seleziona gli accessori"
          summaryLabel="Riepilogo accessori "
        />
      </div>
      <div className="relative mb-8 w-full">
        <OptionSelectComponent
          type={EOptionType.SERVIZI}
          selectLabel="Seleziona i servizi opzionali"
          summaryLabel="Riepilogo servizi"
        />
      </div>
    </div>
  );
};

export default Step2;
