import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "../pages/Home/Home";
import Clienti from "../pages/Clienti/Clienti";
import PDFGenerator from "../components/PDFGenerator/PDFGenerator";
import RootLayout from "../Layout/RootLayout/RootLayout";
import RequireAuth from "@auth-kit/react-router/RequireAuth";
import AuthForm from "../components/Form/AuthForm";
import Users from "../pages/Users/Users";
import PasswordResetForm from "../components/Form/PasswordResetForm";
import Preventivi from "../pages/Preventivi/Preventivi";
import Contratti from "../pages/Contratti/Contratti";
import NewPreventivo from "../pages/Preventivi/NewPreventivo/NewPreventivo";
import PanoramicaCliente from "../pages/Clienti/PanoramicaCliente/PanoramicaCliente";
import AnagraficaCliente from "../pages/Clienti/AnagraficaCliente/AnagraficaCliente";
import PreventiviCliente from "../pages/Clienti/PreventiviCliente/PreventiviCliente";
import ContrattiCliente from "../pages/Clienti/ContrattiCliente/ContrattiCliente";
import TimelineCliente from "../pages/Clienti/TimelineCliente/TimelineCliente";
import NotFound from "../pages/NotFound/NotFound";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { reset as resetPreventivo } from "../features/preventivo/preventivoSlice";
import { reset as resetUI } from "../features/ui/uiSlice";
import { useAppDispatch } from "../app/hooks";
import ViewPreventivo from "../pages/Preventivi/ViewPreventivo/ViewPreventivo";
import Notifiche from "../pages/Notifiche/Notifiche";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import { useApp } from "../components/RealmApp";
import AdminAccessori from "../pages/Admin/AdminAccessori";
import AuthOutlet from "@auth-kit/react-router/AuthOutlet";
import NotificheCliente from "../pages/Clienti/NotificheCliente/NotificheCliente";

const AppRouting = () => {
  const authUser = useAuthUser();
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useAppDispatch();
  const app = useApp();

  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootLayout />,
      //errorElement: <NotFound />,
      children: [
        {
          path: "/",
          index: true,
          element: (
            <RequireAuth fallbackPath={"/login"}>
              <Home />
            </RequireAuth>
          ),
        },
        {
          path: "/clienti",
          element: (
            <RequireAuth fallbackPath={"/login"}>
              <Clienti />
            </RequireAuth>
          ),
        },
        {
          path: "/clienti/:customerId/panoramica",
          element: (
            <RequireAuth fallbackPath={"/login"}>
              <PanoramicaCliente />
            </RequireAuth>
          ),
        },
        {
          path: "/clienti/:customerId/anagrafica",
          element: (
            <RequireAuth fallbackPath={"/login"}>
              <AnagraficaCliente />
            </RequireAuth>
          ),
        },
        {
          path: "/clienti/:customerId/preventivi",
          element: (
            <RequireAuth fallbackPath={"/login"}>
              <PreventiviCliente />
            </RequireAuth>
          ),
        },
        {
          path: "/clienti/:customerId/contratti",
          element: (
            <RequireAuth fallbackPath={"/login"}>
              <ContrattiCliente />
            </RequireAuth>
          ),
        },
        {
          path: "/clienti/:customerId/notifiche",
          element: (
            <RequireAuth fallbackPath={"/login"}>
              <NotificheCliente />
            </RequireAuth>
          ),
        },
        {
          path: "/clienti/:customerId/timeline",
          element: (
            <RequireAuth fallbackPath={"/login"}>
              <TimelineCliente />
            </RequireAuth>
          ),
        },
        {
          path: "/preventivi",
          element: (
            <RequireAuth fallbackPath={"/login"}>
              <Preventivi />
            </RequireAuth>
          ),
        },
        {
          path: "/preventivi/nuovo",
          loader: async ({ params }) => {
            dispatch(resetUI());
            dispatch(resetPreventivo());
            return true;
          },
          element: (
            <RequireAuth fallbackPath={"/login"}>
              <NewPreventivo />
            </RequireAuth>
          ),
        },
        {
          path: "/preventivi/duplica/:preventivoId",
          loader: async ({ params }) => {
            dispatch(resetUI());
            dispatch(resetPreventivo());
            return true;
          },
          element: (
            <RequireAuth fallbackPath={"/login"}>
              <NewPreventivo />
            </RequireAuth>
          ),
        },
        {
          path: "/preventivi/modifica/:preventivoId",
          element: (
            <RequireAuth fallbackPath={"/login"}>
              <NewPreventivo />
            </RequireAuth>
          ),
        },
        {
          path: "/preventivi/visualizza/:preventivoId",
          element: (
            <RequireAuth fallbackPath={"/login"}>
              <ViewPreventivo />
            </RequireAuth>
          ),
        },
        {
          path: "/contratti",
          element: (
            <RequireAuth fallbackPath={"/login"}>
              <Contratti />
            </RequireAuth>
          ),
        },
        {
          path: "/utenti",
          element: (
            <RequireAuth fallbackPath={"/login"}>
              <Users />
            </RequireAuth>
          ),
        },
        {
          path: "/notifiche",
          element: (
            <RequireAuth fallbackPath={"/login"}>
              <Notifiche />
            </RequireAuth>
          ),
        },
        {
          path: "/storico-notifiche",
          element: (
            <RequireAuth fallbackPath={"/login"}>
              <Notifiche />
            </RequireAuth>
          ),
        },
        {
          path: "/pdf",
          element: (
            <RequireAuth fallbackPath={"/login"}>
              <PDFGenerator />
            </RequireAuth>
          ),
        },
        {
          path: "/admin",
          element: <AuthOutlet fallbackPath="/login" />,
          children: [
            {
              path: "/admin/accessori",
              element: <AdminAccessori />,
            },
            {
              path: "/admin/utenti",
              element: <Users />,
            },
          ],
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
    {
      path: "/login",
      element: <AuthForm />,
    },
    {
      path: "/resetpassword",
      element: <PasswordResetForm />,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default AppRouting;
