import { IconCircleCheck } from "@tabler/icons-react";
import { Veicolo } from "../../../queries/veicoli";
import SummaryBox from "../SummaryBox/SummaryBox";
import { Image, List } from "@mantine/core";
import { selectFerroValues } from "../../../features/preventivo/preventivoSlice";
import { useAppSelector } from "../../../app/hooks";
import { decodeTesti } from "../../../utils/utils";

const VeicoloSummary = ({ veicolo }: { veicolo: Veicolo }) => {
  const [veicoloPrice, veicoloDiscount] = useAppSelector(selectFerroValues);
  return (
    <SummaryBox
      label="Veicolo"
      totalItems={1}
      // totalPrice={veicoloPrice}
      // totalDiscount={veicoloDiscount}
      withToggle
    >
      <div className="summary__list flex items-start justify-between gap-2">
        <div className="flex items-start gap-3">
          <Image
            className="h-auto w-20 rounded-lg object-cover"
            src={null}
            fallbackSrc="https://placehold.co/600x400?text=Placeholder"
          />
          <div className="summary__row">
            <span className="block text-sm text-black">
              {veicolo.description}
            </span>
            <List
              icon={<IconCircleCheck className="h-3 w-3" />}
              className="mt-1"
              styles={{
                itemIcon: {
                  marginRight: 4,
                },
              }}
            >
              {veicolo.optionals?.map((optional, index) => (
                <List.Item
                  key={index}
                  className="mr-1 text-xs font-semibold text-black"
                >
                  {optional}
                </List.Item>
              ))}
            </List>
            <div>
          </div>
          </div>
        </div>
      </div>
    </SummaryBox>
  );
};

export default VeicoloSummary;
