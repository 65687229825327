import { Button } from "@mantine/core";
import { IconCheck, IconSquareRoundedX } from "@tabler/icons-react";

export interface ModalLayoutProps {
  title?: string | React.JSX.Element;
  currentUserName: string | undefined;
  children: React.ReactNode;
  notificationButtonLabel?: string;
  notificationButtonLeftSection?: React.JSX.Element;
  toggleNotificationStatus?: () => void;
  onConfirmClick: () => void;
  onCloseClick: () => void;
}

const ModalLayout = ({
  title,
  currentUserName,
  children,
  notificationButtonLabel,
  notificationButtonLeftSection,
  toggleNotificationStatus,
  onConfirmClick,
  onCloseClick,
}: ModalLayoutProps) => {
  return (
    <div className="flex">
      <div className="w-1/3 bg-slate-100 p-8">
        <div className="mb-7 font-medium text-black">{title}</div>
        {currentUserName && (
          <div className="pl-7">
            <span className="mb-2 block text-sm uppercase text-slate-400">
              Utente
            </span>
            <span className="text-sm">{currentUserName}</span>
          </div>
        )}
        {notificationButtonLabel &&
          //@ts-ignore
          title?.props.children[1] === "Modifica notifica" && (
            <div className="mt-5 pl-7">
              <span className="mb-2 block text-sm uppercase text-slate-400">
                Stato
              </span>
              <Button
                variant="outline"
                radius={12}
                onClick={toggleNotificationStatus}
                leftSection={notificationButtonLeftSection}
              >
                {notificationButtonLabel}
              </Button>
            </div>
          )}
      </div>
      <div className="w-2/3 p-8">
        <div className="mb-8 flex justify-end">
          <Button
            onClick={onCloseClick}
            variant="transparent"
            radius={50}
            rightSection={<IconSquareRoundedX />}
          >
            Chiudi
          </Button>
        </div>
        {children}
        <div className="mt-6 flex justify-end">
          <Button
            type="submit"
            onClick={onConfirmClick}
            variant="filled"
            radius={50}
            leftSection={<IconCheck />}
          >
            Conferma
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ModalLayout;
