import { useMemo } from "react";
import {
  MRT_GlobalFilterTextInput,
  MRT_Row,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { Button, NumberFormatter } from "@mantine/core";
import { IconCircleCheck, IconCopy, IconEye } from "@tabler/icons-react";
import { MRT_Localization_IT } from "mantine-react-table/locales/it/index.esm.mjs";
import { useGetOptionalsQuery } from "../../../features/api/apiSlice";
import { EOptionType, Option } from "../../../queries/veicoli";

const AccessoriTable = () => {
  const { data, isLoading } = useGetOptionalsQuery({
    limit: 10000,
    type: EOptionType.ACCESSORI,
    listino: "15/07/2023%2000:00:00",
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableColumnFilterModes: false,
        enableSorting: false,
        enableHiding: false,
        size: 100,
      },
      {
        accessorKey: "label",
        header: "Label",
        enableHiding: false,
      },
      {
        accessorKey: "price",
        header: "Prezzo",
        enableHiding: false,
        Cell: ({ row }: { row: MRT_Row<Option> }) => (
          <>
            <NumberFormatter
              decimalScale={2}
              decimalSeparator=","
              thousandSeparator="."
              value={row.original.price.toFixed(2)}
            />
            {" €"}
          </>
        ),
      },
    ],
    [],
  );

  const table = useMantineReactTable({
    //@ts-ignore
    columns,
    data: data?.optionals || [],
    createDisplayMode: "modal",
    editDisplayMode: "row",
    enableColumnDragging: false,
    enableColumnActions: false,
    enableColumnPinning: true,
    enableRowActions: false,
    positionActionsColumn: "last",
    enableRowSelection: true,
    enableColumnOrdering: true,
    enableGlobalFilter: true,
    globalFilterFn: "contains",
    enableFilterMatchHighlighting: true,
    enablePagination: false,
    enableBottomToolbar: false,
    enableFacetedValues: true,
    enableColumnResizing: false,
    enableStickyHeader: true,
    enableGlobalFilterModes: true,
    localization: MRT_Localization_IT,
    state: {
      isLoading: isLoading,
      density: "xs",
      columnPinning: {
        right: ["mrt-row-actions"],
      },
    },
    mantineSearchTextInputProps: {
      placeholder: "Cerca...",
      variant: "filled",
    },
    mantineTableHeadCellProps: {
      bg: "rgb(226,232,240,1)",
      frameBorder: "none",
    },
    mantinePaperProps: {
      withBorder: false,
      shadow: "none",
      radius: "md",
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "",
      },
    },
    // renderRowActionMenuItems: ({ row }) => (
    //   <div className="flex items-center gap-3">
    //     <Tooltip label="Visualizza" position="bottom">
    //       <Button
    //         variant="transparent"
    //         className="flex h-8 w-8 items-center justify-center rounded-full p-0 hover:bg-slate-100"
    //       >
    //         <IconEye className="h-5 w-5" />
    //       </Button>
    //     </Tooltip>
    //     <Tooltip label="Duplica" position="bottom">
    //       <Button
    //         variant="transparent"
    //         className="flex h-8 w-8 items-center justify-center rounded-full p-0 hover:bg-slate-100"
    //       >
    //         <IconCopy className="h-5 w-5" />
    //       </Button>
    //     </Tooltip>
    //     <Tooltip label="Completa" position="bottom">
    //       <Button
    //         variant="transparent"
    //         className="flex h-8 w-8 items-center justify-center rounded-full p-0 hover:bg-slate-100"
    //       >
    //         <IconCircleCheck className="h-5 w-5" />
    //       </Button>
    //     </Tooltip>
    //   </div>
    // ),
    renderTopToolbar: ({ table }) => {
      const isOneSelected =
        Object.keys(table.getState().rowSelection).length > 0;
      const isManySelected =
        Object.keys(table.getState().rowSelection).length > 1;

      return (
        <div className="flex items-center justify-between bg-slate-200 p-4">
          {isOneSelected && (
            <div className="flex items-center gap-5">
              {!isManySelected && (
                <>
                  <Button rightSection={<IconEye size={18} />}>
                    Visualizza
                  </Button>

                  <Button rightSection={<IconCopy size={18} />}>Duplica</Button>
                </>
              )}
              <Button rightSection={<IconCircleCheck size={18} />}>
                Completa
              </Button>
            </div>
          )}
          <div className="ml-auto flex items-center gap-2">
            <div className="has-global-search flex items-center gap-2">
              <MRT_GlobalFilterTextInput table={table} />
              <MRT_ToggleGlobalFilterButton table={table} />
            </div>
            <MRT_ToggleFiltersButton table={table} />
          </div>
        </div>
      );
    },
  });

  return <MantineReactTable table={table} />;
};

export default AccessoriTable;
