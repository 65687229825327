import { NavLink } from "react-router-dom";

const NotificationNavigation = () => {
  const notificationNavigationClasses = {
    active:
      "relative text-sm text-slate-900 font-semibold pb-2 before:w-[calc(100%+12px)] before:h-[3px] before:bg-slate-900 before:absolute before:-left-[6px] before:bottom-0 before:rounded-full",
    idle: "text-sm text-slate-600 pb-2",
  };

  return (
    <div className="flex justify-center gap-20 pt-4">
      <NavLink
        to="/notifiche"
        className={({ isActive }) =>
          isActive
            ? notificationNavigationClasses.active
            : notificationNavigationClasses.idle
        }
      >
        Notifiche attive
      </NavLink>
      <NavLink
        to="/storico-notifiche"
        className={({ isActive }) =>
          isActive
            ? notificationNavigationClasses.active
            : notificationNavigationClasses.idle
        }
      >
        Storico notifiche
      </NavLink>
    </div>
  );
};

export default NotificationNavigation;
