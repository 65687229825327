import {
  Document,
  Page,
  Text,
  StyleSheet,
  Font,
  Image,
  View,
} from "@react-pdf/renderer"
import LogoPreventivo from "../assets/logo_preventivo.png"
import Html from "react-pdf-html"
import PoppinsRegular from "../fonts/Poppins-Regular.ttf"
import PoppinsBold from "../fonts/Poppins-Bold.ttf"
import PoppinsSemibold from "../fonts/Poppins-SemiBold.ttf"
import {
  decodePreventivoString,
  transformTimestampToLocaleDateAndTime,
} from "../../../utils/utils"
// import CarImage from "../assets/ford_ranger.png"

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    paddingHorizontal: 20,
    paddingBottom: 50,
  },
  title: {
    fontFamily: "PoppinsRegular",
    fontSize: 9,
    color: "#000",
  },
  content: {
    fontSize: 9,
    textAlign: "center",
    margin: 20,
  },
  logo: {
    height: 64,
    width: "auto",
  },
  image: {
    height: 64,
    width: "auto",
  },
  flexView: {
    flexDirection: "row",
  },
})

const PreventivoPdfRenderer = ({ preventivoData }: any) => {
  Font.register({
    family: "PoppinsBold",
    fonts: [{ src: PoppinsBold }],
  })

  Font.register({
    family: "PoppinsRegular",
    fonts: [{ src: PoppinsRegular }],
  })

  Font.register({
    family: "PoppinsSemibold",
    fonts: [{ src: PoppinsSemibold }],
  })

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* START: Header */}
        <View
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingVertical: 20,
            marginBottom: 0,
          }}
        >
          <Image style={styles.logo} src={LogoPreventivo} />
          {/* <Image style={styles.image} src={CarImage} /> */}
        </View>
        {/* END: Header */}

        {/* START: Venditore - Spett.le row */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginHorizontal: 24,
            marginBottom: 20,
            gap: 8,
          }}
        >
          <View
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              marginBottom: 8,
            }}
          >
            <Text style={styles.title}>Venditore:</Text>
            <Text
              style={{
                ...styles.title,
                fontFamily: "PoppinsSemibold",
                fontWeight: "semibold",
                marginLeft: 4,
              }}
            >
              {preventivoData.agente?.name} -{" "}
            </Text>
            <Text style={{ ...styles.title }}>
              {preventivoData?.agente?.email}
            </Text>
          </View>

          <View
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "row",
              gap: 8,
            }}
          >
            <Text
              style={{
                ...styles.title,
                fontFamily: "PoppinsSemibold",
                fontWeight: "semibold",
              }}
            >
              Spett.le:
            </Text>
            <View>
              <Text style={{ ...styles.title }}>
                {preventivoData?.currentCliente?.nome}
              </Text>
              <Text style={{ ...styles.title }}>
                {preventivoData?.currentCliente?.indirizzo}
              </Text>
            </View>
          </View>
        </View>
        {/* END: Venditore - Spett.le row */}

        {/* START: Data - c.a.sig. */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginHorizontal: 24,
            marginBottom: 20,
            gap: 8,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 8,
            }}
          >
            <View style={{ width: "50%" }}>
              <Text style={{ ...styles.title }}>
                {transformTimestampToLocaleDateAndTime(
                  preventivoData?.firstSavedAt || 0,
                  false,
                  false,
                )}
              </Text>
            </View>
            <View
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "row",
                gap: 8,
              }}
            >
              <Text style={styles.title}>c.a.</Text>
              <Text style={styles.title}>
                {decodePreventivoString(
                  preventivoData?.currentCliente?.cortese_attenzione || "",
                )}
              </Text>
            </View>
          </View>
        </View>
        {/* END: Data - c.a.sig */}

        {/* START: Oggetto */}
        <View
          style={{
            marginHorizontal: 24,
            marginBottom: 20,
          }}
        >
          <Text style={styles.title}>Oggetto:</Text>
          <Html style={{ ...styles.title, margin: 0 }}>
            {`
              <style>
                strong, b {
                  font-family: "PoppinsSemibold";
                  font-weight: 600;
                }
              </style>
              ${decodePreventivoString(preventivoData?.oggetto || "")}
             `}
          </Html>
        </View>
        {/* END: Oggetto */}

        {/* START: Modello */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginHorizontal: 24,
            marginBottom: 20,
          }}
        >
          <Text
            style={{
              ...styles.title,
              marginRight: 2,
              fontFamily: "PoppinsSemibold",
              fontWeight: "semibold",
            }}
          >
            Modello:
          </Text>
          <Html
            style={{
              ...styles.title,
              color: "#334155",
              textTransform: "uppercase",
              fontFamily: "PoppinsSemibold",
              fontWeight: "semibold",
            }}
          >
            {decodePreventivoString(preventivoData?.modello || "")}
          </Html>
        </View>
        {/* END: Modello */}

        {/* START: Dati tecnici */}
        <View
          style={{
            marginHorizontal: 24,
            marginBottom: 20,
          }}
        >
          <Text
            style={{
              ...styles.title,
              marginBottom: 8,
              fontFamily: "PoppinsSemibold",
              fontWeight: "semibold",
            }}
          >
            Dati tecnici:
          </Text>
          {/* START: Motore */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 4,
              marginBottom: 8,
            }}
          >
            <Text style={styles.title}>Motore: </Text>
            <Html style={styles.title}>
              {`
              <style>
                strong, b {
                  font-family: "PoppinsSemibold";
                  font-weight: 600;
                }
              </style>
              ${decodePreventivoString(preventivoData?.motore || "")}
             `}
            </Html>
          </View>
          {/* END: Motore */}
          {/* START: Dimensioni */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 4,
              marginBottom: 8,
            }}
          >
            <Text style={styles.title}>Dimensioni: </Text>
            <Html style={styles.title}>
              {`
              <style>
                strong, b {
                  font-family: "PoppinsSemibold";
                  font-weight: 600;
                }
              </style>
              ${decodePreventivoString(preventivoData?.dimensioni || "")}
             `}
            </Html>
          </View>
          {/* END: Dimensioni */}
          {/* START: Dotazioni */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 4,
              marginBottom: 8,
            }}
          >
            <Text style={styles.title}>Dotazioni: </Text>
            <Html style={styles.title}>
              {`
              <style>
                strong, b {
                  font-family: "PoppinsSemibold";
                  font-weight: 600;
                }
              </style>
              ${decodePreventivoString(preventivoData?.dotazioni_guida || "")}
             `}
            </Html>
            <Html style={styles.title}>
              {`
              <style>
                strong, b {
                  font-family: "PoppinsSemibold";
                  font-weight: 600;
                }
              </style>
              ${decodePreventivoString(preventivoData?.dotazioni_sicurezza || "")}
             `}
            </Html>
            <Html style={styles.title}>
              {`
              <style>
                strong, b {
                  font-family: "PoppinsSemibold";
                  font-weight: 600;
                }
              </style>
              ${decodePreventivoString(preventivoData?.dotazioni_interne || "")}
             `}
            </Html>
            <Html style={styles.title}>
              {`
              <style>
                strong, b {
                  font-family: "PoppinsSemibold";
                  font-weight: 600;
                }
              </style>
              ${decodePreventivoString(preventivoData?.dotazioni_tecnologia || "")}
             `}
            </Html>
          </View>
          {/* END: Dotazioni */}
        </View>
        {/* END: Dati tecnici */}

        {/* START: Caratteristiche versione */}
        <View
          style={{
            marginHorizontal: 24,
            marginBottom: 20,
          }}
        >
          <Html style={styles.title}>
            {`
              <style>
                strong, b {
                  font-family: "PoppinsSemibold";
                  font-weight: 600;
                }
              </style>
              ${decodePreventivoString(preventivoData?.caratteristiche_versione || "")}
             `}
          </Html>
        </View>
        {/* END: Caratteristiche versione */}
        {/* START: Optional */}
        <View
          style={{
            marginHorizontal: 24,
            marginBottom: 8,
          }}
        >
          <Text style={styles.title}>
            Optional: € {preventivoData?.optionalTotal[0].toLocaleString()}
          </Text>
          <Text style={styles.title}>
            {preventivoData?.optionals
              ?.map((optional: any) => optional.label)
              .join(" + ")}
          </Text>
        </View>
        {/* END: Optional */}
        {/* START: Accessori */}
        <View
          style={{
            marginHorizontal: 24,
            marginBottom: 8,
          }}
        >
          <Text style={styles.title}>
            Accessori: € {preventivoData?.accessoriTotal[0].toLocaleString()}
          </Text>
          <Text style={styles.title}>
            {preventivoData?.accessori
              ?.map((accessorio: any) => accessorio.label)
              .join(" + ")}
          </Text>
        </View>
        {/* END: Accessori */}
        {/* START: Servizi */}
        <View
          style={{
            marginHorizontal: 24,
            marginBottom: 8,
          }}
        >
          <Text style={styles.title}>
            Servizi: € {preventivoData?.serviziTotal[0].toLocaleString()}
          </Text>
          <Text style={styles.title}>
            {preventivoData?.servizi
              ?.map((servizio: any) => servizio.label)
              .join(" + ")}
          </Text>
        </View>
        {/* END: Servizi */}

        {/* START: Box totale */}
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            position: "absolute",
            bottom: 20,
            right: 20,
            marginHorizontal: 24,
            borderStyle: "solid",
            borderColor: "#334155",
            borderWidth: 0.5,
            padding: 8,
            borderRadius: 4,
          }}
        >
          <Text
            style={{
              marginRight: 4,
              fontSize: 9,
              borderBottomStyle: "solid",
              borderBottomColor: "#334155",
              borderBottomWidth: 0.5,
              paddingBottom: 8,
              marginBottom: 8,
            }}
          >
            Totale su strada IVA{" "}
            {preventivoData?.show_iva ? "INCLUSA" : "ESCLUSA"}:
          </Text>
          <Text
            style={{
              fontSize: 11,
              fontFamily: "PoppinsSemibold",
              fontWeight: "semibold",
              textAlign: "right",
            }}
          >
            {preventivoData?.priceFinal?.toLocaleString()} €
          </Text>
        </View>
        {/* END: Box totale */}
      </Page>
    </Document>
  )
}
export default PreventivoPdfRenderer
