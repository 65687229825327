import CustomersTable from "../../components/Table/CustomersTable";
import { Button, Container } from "@mantine/core";
import {
  IconAddressBook,
  IconFileExport,
  IconFileImport,
} from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import CustomerForm from "../../components/Form/CustomerForm";
import useAuthUserData from "../../hooks/useAuthUserData";

export default function Clienti() {
  const { authUserName } = useAuthUserData();
  return (
    <>
      <section className="px-10 py-5">
        <div className="flex gap-5">
          <Button
            variant="outline"
            radius="xl"
            leftSection={<IconAddressBook size={18} />}
            onClick={() =>
              modals.open({
                padding: 0,
                centered: true,
                withCloseButton: false,
                size: "70%",
                radius: 26,
                children: (
                  <CustomerForm
                    currentUserName={authUserName}
                    onCloseClick={() => modals.closeAll()}
                  />
                ),
              })
            }
          >
            Nuovo cliente
          </Button>
          <Button
            variant="outline"
            radius="xl"
            leftSection={<IconFileExport size={18} />}
          >
            Esporta CSV
          </Button>
          <Button
            variant="outline"
            radius="xl"
            leftSection={<IconFileImport size={18} />}
          >
            Importa CSV
          </Button>
        </div>
      </section>
      <Container
        size="100%"
        className="mx-10 rounded-xl bg-transparent p-0 shadow-md"
      >
        <CustomersTable />
      </Container>
    </>
  );
}
