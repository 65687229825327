import { Select } from "@mantine/core";
import SummaryVerticalBox from "../SummaryVerticalBox/SummaryVerticalBox";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  selectModalitaPagamento,
  setModalitaPagamento,
} from "../../../features/preventivo/preventivoSlice";
import { PAYMENTS_TYPES } from "../../../queries/preventivi";

const SelectPaymentMethod = ({ isReadOnly }: { isReadOnly: boolean }) => {
  const dispatch = useAppDispatch();

  const handlePaymentMethodChange = (paymentMethod: string | null) => {
    paymentMethod && dispatch(setModalitaPagamento(paymentMethod));
  };

  const currentValue = useAppSelector(selectModalitaPagamento);

  return (
    <SummaryVerticalBox label="Modalità di pagamento">
      <Select
        data={PAYMENTS_TYPES.map((p) => p.label)}
        placeholder="Modalità di pagamento"
        value={currentValue}
        onChange={(sel) => handlePaymentMethodChange(sel)}
        disabled={isReadOnly}
        styles={{
          input: {
            backgroundColor: "#f1f5f9",
            border: "none",
            borderRadius: 12,
          },
        }}
      />
    </SummaryVerticalBox>
  );
};

export default SelectPaymentMethod;
