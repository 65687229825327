import { Select } from "@mantine/core";
import { useGetAnagraficheQuery } from "../../../features/api/apiSlice";
import {
  selectCliente,
  setCliente,
} from "../../../features/preventivo/preventivoSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";

const SelectCliente = ({ disabled }: { disabled?: boolean }) => {
  const dispatch = useAppDispatch();
  const { data } = useGetAnagraficheQuery({ limit: 200 });
  const currentCliente = useAppSelector(selectCliente);
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const idCliente = params.get("id_cliente");
  return (
    <Select
      label="Cliente"
      placeholder="Seleziona il cliente"
      searchable
      value={currentCliente?._id ?? idCliente ?? null}
      disabled={disabled}
      data={
        data?.anagraficas?.map(({ _id, nome }) => ({
          value: _id,
          label: `${nome}`,
        })) || []
      }
      onChange={(sel) => {
        const cliente = data?.anagraficas.find(({ _id }) => _id === sel);
        cliente && dispatch(setCliente(cliente));
      }}
      styles={{ input: { height: 44 } }}
      radius={12}
    />
  );
};

export default SelectCliente;
