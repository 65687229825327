import { Anagrafica } from "../../../../queries/anagrafica";
import CustomerDataContainer from "./CustomerDataContainer/CustomerDataContainer";
import CustomerDataRow from "./CustomerDataRow/CustomerDataRow";

const CustomerData = ({ currentCustomer }: { currentCustomer: Anagrafica }) => {
  return (
    <>
      <CustomerDataContainer containerLabel="Dati generici">
        <CustomerDataRow rowLabel="Nome" rowValue={currentCustomer?.nome} />
        <CustomerDataRow
          rowLabel="Codice fiscale"
          rowValue={currentCustomer?.cf}
        />
        <CustomerDataRow
          rowLabel="Tipologia"
          rowValue={currentCustomer?.tipologia}
        />
      </CustomerDataContainer>
      <CustomerDataContainer containerLabel="Dati fiscali">
        <CustomerDataRow
          rowLabel="Partiva IVA"
          rowValue={currentCustomer?.piva}
        />
        <CustomerDataRow
          rowLabel="Valuta"
          rowValue={currentCustomer?.valuta}
        />
      </CustomerDataContainer>
      <CustomerDataContainer containerLabel="Indirizzi">
        <CustomerDataRow
          rowLabel="Indirizzo"
          rowValue={currentCustomer?.indirizzo}
        />
        <CustomerDataRow rowLabel="Civico" rowValue={currentCustomer?.civico} />
        <CustomerDataRow
          rowLabel="Località"
          rowValue={currentCustomer?.localita}
        />
        <CustomerDataRow
          rowLabel="Provincia"
          rowValue={currentCustomer?.prov}
        />
        <CustomerDataRow
          rowLabel="Nazione"
          rowValue={currentCustomer?.nazione}
        />
      </CustomerDataContainer>
      <CustomerDataContainer containerLabel="Contatti">
        <CustomerDataRow rowLabel="Email" rowValue={currentCustomer?.email} />
        <CustomerDataRow rowLabel="PEC" rowValue={currentCustomer?.pec} />
        <CustomerDataRow rowLabel="Telefono" rowValue={currentCustomer?.tel} />
      </CustomerDataContainer>
      <CustomerDataContainer containerLabel="Referenti">
        {currentCustomer?.referenti?.map((referente, index) => (
          <CustomerDataRow
            key={index}
            rowLabel="Referente"
            rowValue={referente}
          />
        ))}
      </CustomerDataContainer>
      <CustomerDataContainer containerLabel="Note">
        {currentCustomer?.note && (
          <CustomerDataRow rowLabel="Note" rowValue={currentCustomer?.note} />
        )}
      </CustomerDataContainer>
    </>
  );
};

export default CustomerData;
