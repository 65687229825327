import {
  IconCar,
  IconPrinter,
  IconSettings,
  IconTag,
} from "@tabler/icons-react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectActiveStep, setActiveStep } from "../../../features/ui/uiSlice";

const PreventivoSteps = ({ preventivoId }: { preventivoId?: string }) => {
  const dispatch = useAppDispatch();
  const activeStep = useAppSelector(selectActiveStep);
  const steps = [
    { id: 1, label: "Veicolo base", icon: <IconCar className="h-5 w-5" /> },
    {
      id: 2,
      label: "Opzioni e servizi",
      icon: <IconSettings className="h-5 w-5" />,
    },
    {
      id: 3,
      label: "Sconti e pagamenti",
      icon: <IconTag className="h-5 w-5" />,
    },
    {
      id: 4,
      label: "Personalizza",
      icon: <IconPrinter className="h-5 w-5" />,
    },
  ];

  const preventivoStepsClasses = {
    active:
      "flex items-end gap-2 cursor-pointer relative text-sm text-slate-900 font-semibold pb-2 before:w-[calc(100%+12px)] before:h-[3px] before:bg-slate-900 before:absolute before:-left-[6px] before:bottom-0 before:rounded-full",
    idle: "flex items-end gap-2 cursor-pointer text-sm text-slate-600 pb-2",
  };

  const handleStepClick = (id: number) => {
    dispatch(setActiveStep(id));
  };

  return (
    <ul className="mx-10 -mt-6 flex justify-center gap-20 rounded-xl bg-white pt-6 shadow-md">
      {preventivoId
        ? steps.map((step) => (
            <li
              key={step.id}
              className={
                activeStep === step.id
                  ? preventivoStepsClasses.active
                  : preventivoStepsClasses.idle
              }
              onClick={() => handleStepClick(step.id)}
            >
              {step.icon}
              {step.label}
            </li>
          ))
        : steps.slice(0, 3).map((step) => (
            <li
              key={step.id}
              className={
                activeStep === step.id
                  ? preventivoStepsClasses.active
                  : preventivoStepsClasses.idle
              }
              onClick={() => handleStepClick(step.id)}
            >
              {step.icon}
              {step.label}
            </li>
          ))}
    </ul>
  );
};

export default PreventivoSteps;
