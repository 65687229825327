import { Button, Loader } from "@mantine/core";
import {
  IconArrowLeft,
  IconArrowRight,
  IconDeviceFloppy,
} from "@tabler/icons-react";
import { Veicolo } from "../../../../queries/veicoli";

export interface BottomActionsProps {
  activeStep: number;
  isSavingEnabled: {
    state: boolean;
    isLoading: boolean;
  };
  firstStepCompleted: "" | Veicolo | undefined;
  handleSavePreventivo: () => void;
  onPrevClick: () => void;
  onNextClick: () => void;
  saveLabel: string;
}

const BottomActions = ({
  activeStep,
  isSavingEnabled,
  firstStepCompleted,
  handleSavePreventivo,
  onPrevClick,
  onNextClick,
  saveLabel,
}: BottomActionsProps) => {
  const handlePrevClick = () => {
    onPrevClick();
  };
  const handleNextClick = () => {
    onNextClick();
  };

  return (
    <div
      className={`mb-5 mt-10 flex ${activeStep === 1 ? "justify-end" : "justify-between"}`}
    >
      {(activeStep === 2 || activeStep === 3) && (
        <Button
          radius={50}
          size="md"
          variant="outline"
          leftSection={<IconArrowLeft />}
          onClick={handlePrevClick}
        >
          Indietro
        </Button>
      )}

      {(activeStep === 1 || activeStep === 2) && (
        <Button
          radius={50}
          size="md"
          rightSection={<IconArrowRight />}
          onClick={handleNextClick}
        >
          Avanti
        </Button>
      )}

      {activeStep === 3 && (
        <Button
          radius={50}
          size="md"
          leftSection={
            isSavingEnabled.isLoading ? (
              <Loader color="blue" size="xs" />
            ) : (
              <IconDeviceFloppy />
            )
          }
          onClick={handleSavePreventivo}
          disabled={false}
        >
          {saveLabel}
        </Button>
      )}
    </div>
  );
};

export default BottomActions;
