import { NavLink } from "react-router-dom";

const CustomerNavigation = ({ customerId }: { customerId: string }) => {
  const customersNavigation = [
    { label: "Panoramica", path: `/clienti/${customerId}/panoramica` },
    { label: "Anagrafica", path: `/clienti/${customerId}/anagrafica` },
    { label: "Preventivi", path: `/clienti/${customerId}/preventivi` },
    { label: "Contratti", path: `/clienti/${customerId}/contratti` },
    { label: "Notifiche", path: `/clienti/${customerId}/notifiche` },
    // { label: "Timeline", path: `/clienti/${customerId}/timeline` },
  ];

  const customersNavigationClasses = {
    active:
      "relative text-sm text-slate-900 font-semibold pb-2 before:w-[calc(100%+12px)] before:h-[3px] before:bg-slate-900 before:absolute before:-left-[6px] before:bottom-0 before:rounded-full",
    idle: "text-sm text-slate-600 pb-2",
  };

  return (
    <div className="flex justify-center gap-20 pt-4">
      {customersNavigation.map((navigation) => (
        <NavLink
          key={navigation.label}
          to={navigation.path}
          className={({ isActive }) =>
            isActive
              ? customersNavigationClasses.active
              : customersNavigationClasses.idle
          }
        >
          {navigation.label}
        </NavLink>
      ))}
    </div>
  );
};

export default CustomerNavigation;
