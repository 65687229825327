import { Avatar, Button, Menu } from "@mantine/core";
import { IconBell, IconLogout } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import NotificationBadge from "../NotificationBadge/NotificationBadge";

export interface AccountMenuProps {
  onClick: () => {};
  isSidebarCollapsed: boolean;
  notificationsLength: number;
  currentUserName: string | undefined;
}

const AccountMenu = ({
  onClick,
  isSidebarCollapsed,
  notificationsLength,
  currentUserName,
}: AccountMenuProps) => {
  const btnClasses = isSidebarCollapsed
    ? "w-9 h-9 flex items-center justify-center rounded-full p-0 bg-slate-500"
    : "text-md h-10 w-full rounded-full px-5 bg-slate-500";

  return (
    <div className="relative">
      {notificationsLength > 0 && (
        <NotificationBadge notificationsLength={notificationsLength} />
      )}
      <Menu
        position="top"
        offset={10}
        trigger="hover"
        openDelay={100}
        closeDelay={200}
        shadow="md"
        width={200}
      >
        <Menu.Target>
          <Button variant="filled" className={btnClasses}>
            <div className="flex items-center justify-center">
              <Avatar variant="transparent" color="white" />
              {!isSidebarCollapsed && <span>{currentUserName}</span>}
            </div>
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label>Menu utente</Menu.Label>
          <Link to="/notifiche">
            <Menu.Item leftSection={<IconBell className="h-4 w-4" />}>
              <div className="flex items-center justify-between">
                <span>Notifiche</span>
                {notificationsLength > 0 && (
                  <span className="block h-2 w-2 rounded-full bg-error"></span>
                )}
              </div>
            </Menu.Item>
          </Link>
          <Menu.Item
            onClick={onClick}
            leftSection={<IconLogout className="h-4 w-4" />}
          >
            Logout
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};

export default AccountMenu;
