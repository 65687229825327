import { Button, Container } from "@mantine/core";
import AccessoriTable from "../../components/Admin/AdminTable/AccessoriTable";
import { IconAlertCircle, IconCirclePlus } from "@tabler/icons-react";

const AdminAccessori = () => {
  return (
    <>
      <section className="px-10 py-5">
        <div className="flex gap-5">
          <Button
            variant="outline"
            radius="xl"
            leftSection={<IconCirclePlus size={18} />}
            // onClick={() => {
            //   modals.open({
            //     padding: 0,
            //     withCloseButton: false,
            //     size: "70%",
            //     radius: 26,
            //     centered: true,
            //     children: <NotificationForm authUser={authUser} />,
            //   });
            // }}
          >
            Nuovo accessorio
          </Button>
        </div>
      </section>
      <Container
        size="100%"
        className="mx-10 rounded-xl bg-transparent p-0 shadow-md"
      >
        <AccessoriTable />
      </Container>
    </>
  );
};

export default AdminAccessori;
