import { Tooltip } from "@mantine/core";
import { NavLink } from "react-router-dom";

interface NavigationItemProps {
  destination: string;
  children: React.ReactNode | string;
  isSidebarCollapsed: boolean;
  tooltipLabel: string;
  tooltipVisible: boolean;
}

const NavigationItem = ({
  destination,
  children,
  isSidebarCollapsed,
  tooltipLabel,
  tooltipVisible,
}: NavigationItemProps) => {
  const activeClasses = "nav-link--active font-medium !bg-slate-800";
  const collapsedClasses = isSidebarCollapsed
    ? "flex h-9 w-9 items-center justify-center text-md rounded-xl p-0 bg-slate-600 text-white hover:bg-slate-500 mb-1"
    : "flex items-center gap-2 w-full h-full text-white hover:bg-slate-600 rounded-xl px-3 py-2";

  return (
    <li>
      <Tooltip
        label={tooltipLabel}
        position="right"
        className={tooltipVisible ? "" : "hidden"}
      >
        <NavLink
          to={destination}
          className={({ isActive }) =>
            isActive ? `${collapsedClasses} ${activeClasses}` : collapsedClasses
          }
        >
          {children}
        </NavLink>
      </Tooltip>
    </li>
  );
};

export default NavigationItem;
