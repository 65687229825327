import { NumberInput } from "@mantine/core";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setAnticipo } from "../../../features/preventivo/preventivoSlice";

const InputAnticipo = ({ isReadOnly }: { isReadOnly: boolean }) => {
  const dispatch = useAppDispatch();
  const anticipoValue = useAppSelector((state) => state.preventivo.anticipo);

  const handleInputChange = (value: string | number) => {
    dispatch(setAnticipo(+value));
  };

  return (
    <NumberInput
      min={0}
      value={anticipoValue}
      onChange={(value) => handleInputChange(value)}
      allowNegative={false}
      decimalScale={2}
      placeholder="-"
      rightSection={<span></span>}
      thousandSeparator="."
      decimalSeparator=","
      disabled={isReadOnly}
      styles={{
        input: {
          backgroundColor: "#f1f5f9",
          textAlign: "center",
          border: "none",
          width: "100%",
          borderRadius: 12,
          paddingInline: 12,
        },
      }}
    />
  );
};

export default InputAnticipo;
