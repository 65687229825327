const templates = {
  'OGGETTO': "Con la presente abbiamo il piacere di sottoporre alla Vs. cortese attenzione la nostra migliore offerta per l’acquisto di un autocarro FORD",
  'MODELLO': "{MODELLO_VEICOLO} {PC_TIPOLOGIA_HL} {MODELLO_VERSIONE} {MODELLO_MODELYEAR}",
  'PC_TIPOLOGIA_HL_CUSTOM': "{PORTATA} {MODELLO_TIPOLOGIA} L{LUNGHEZZA} H{ALTEZZA}",
  'DOTAZIONI_1_ASSISTENZA_ALLA_GUIDA_CUSTOM': "TECH PACK 2: sistema frenata automatica con riconoscimento pedoni, Assistenza mantenimento di corsia, Driver alert, Cruise control, Riconoscimento segnali stradali, Intelligent speed assist, Sensori di parcheggio anteriori e posteriori, Telecamera posteriore",
  'DOTAZIONI_SICUREZZA_CUSTOM': "Sicurezza Attiva: ABS con EBD ( ripartitore elettronico della frenata ) +  4 freni a disco  + ESC ( stabilità elettronica ) + TCS ( controllo della trazione ) + HLA assistenza alla partenza in salita + EBA ( assistenza alla frenata d’emergenza) + Luci automatiche della frenata d’emergenza",
  'DOTAZIONI_INTERNE_CUSTOM': "Lunghezza vano di carico {MM_VANO} mm., n.6 ganci di fissaggio carico, porte posteriori a doppio battente, antifurto immobilizzazione a codice variabile, airbag conducente, volante regolabile in altezza e profondità",
  'DOTAZIONI_TECNOLOGIA_CUSTOM': "SYNC 4 : Radio DAB + Touch 13'' + Apple carplay / Android auto + Modem 5G con localizzazione + Bluetooth.",
  'MY_CUSTOM': "My 2024.50",
  'MY_TRANSIT': "My 2024.75",
  'MY_COURIER': "My 2024",
  'MY_CONNECT': "My 2024.50",
  'MY_RANGER': "My",
  'MOTORE_TRANSIT_2000': "Motore 2.0 EcoBlue TDCI {CV} CV {NM} NM Euro 6D-ISC-FCM, 16 valvole, {VALORE_NM} NM di coppia, turbina a geometria variabile, Batteria con ricarica rigenerativa intelligente, Start & Stop, Modalità di guida selezionabile: Normale / Eco, {CAMBIO}",
  'MOTORE_CUSTOM_2000_DIESEL': "Motore 2.0 EcoBlue TDCI {CV} CV {NM} NM Euro 6E-ISC-FCM, 16 valvole, 360 NM di coppia, turbina a geometria variabile, Batteria con ricarica rigenerativa intelligente, Start & Stop, Modalità di guida selezionabile: Normale / Eco / Bassa aderenza; {CAMBIO}",
  'MOTORE_COURIER_2000_DIESEL': "Motorizzazione: 2.0 Diesel {CV} CV  {NM} NM Turbo con interculer, Start&Stop, {CAMBIO}",
  'CAMBIO_TR_MANUALE': "Cambio  Manuale a 6 rapporti",
  'CAMBIO_TR_CUSTOM_AUTOMATICO': "Cambio Automatico SelectShift a 8 velocità",
  


}

export default templates;