import { Button, Container, Flex, List, TextInput } from "@mantine/core"
import TotalBox from "../../../components/Preventivo/TotalBox/TotalBox"
import Step3 from "../NewPreventivo/Step3/Step3"
import usePreventivoData from "../../../hooks/usePreventivoData"
import { Link, useNavigate, useParams } from "react-router-dom"
import {
  IconAlertCircle,
  IconCopy,
  IconEdit,
  IconTrash,
} from "@tabler/icons-react"
import PdfProvider from "../../../components/Preventivo/PdfProvider/PdfProvider"
import SelectCliente from "../../../components/Preventivo/PreventivoInputs/SelectCliente"
import { modals } from "@mantine/modals"
import {
  deleteModalProps,
  onModalDelete,
} from "../../../components/Modal/ModalDelete/ModalDelete"
import { useDeletePreventiviMutation } from "../../../features/api/apiSlice"
import { transformTimestampToLocaleDateAndTime } from "../../../utils/utils"
import NotificationForm from "../../../components/Form/NotificationForm"
import useAuthUserData from "../../../hooks/useAuthUserData"
import { getColorFromTemperature } from "../../../utils/utils"

const ViewPreventivo = () => {
  const { authUser } = useAuthUserData()
  const navigate = useNavigate()
  const [deletePreventivi] = useDeletePreventiviMutation()
  let { preventivoId } = useParams()
  const {
    optionals,
    permuteTotal,
    permute,
    servizi,
    accessori,
    veicolo,
    scontoGrandTotal,
    data,
    temperatura,
    dataNotifications,
    sconti,
  } = usePreventivoData({ preventivoId })

  const handleRemovePreventivo = () => {
    modals.openConfirmModal({
      ...deleteModalProps("preventivo", "preventivi", [
        preventivoId,
      ] as string[]),
      onConfirm: () =>
        onModalDelete({
          selectedIds: [preventivoId] as string[],
          deleteFunction: deletePreventivi,
          singularElement: "preventivo",
          pluralElement: "preventivi",
          navigate: navigate,
          isIdFromParams: true,
        }),
    })
  }

  const handleNotificationClick = () => {
    modals.open({
      padding: 0,
      withCloseButton: false,
      size: "70%",
      radius: 26,
      centered: true,
      children: (
        <NotificationForm preventivoId={preventivoId} authUser={authUser} />
      ),
    })
  }

  const filterActiveNotifications = dataNotifications?.notifications.filter(
    (notification) => notification.dismissed_at === null,
  )

  return (
    <>
      <div className="sticky top-0 z-30">
        <Flex
          mb={16}
          justify="space-between"
          className="progress__header px-10 py-5"
          gap={32}
        >
          <div className="flex items-center gap-5">
            <Link to={`/preventivi/duplica/${preventivoId}`}>
              <Button
                variant="outline"
                radius="xl"
                leftSection={<IconCopy size={18} />}
              >
                Duplica
              </Button>
            </Link>
            <Link to={`/preventivi/modifica/${preventivoId}`}>
              <Button
                variant="outline"
                radius="xl"
                leftSection={<IconEdit size={18} />}
              >
                Modifica
              </Button>
            </Link>
            <Button
              variant="outline"
              radius="xl"
              leftSection={<IconAlertCircle size={18} />}
              onClick={handleNotificationClick}
            >
              Notifica
            </Button>
            {data?.preventivi && (
              <PdfProvider
                preventivoId={preventivoId || "0000"}
                isIconOnly={false}
              />
            )}
            <Button
              color="pink"
              radius="xl"
              leftSection={<IconTrash size={18} />}
              onClick={handleRemovePreventivo}
            >
              Elimina
            </Button>
          </div>
          <div className="flex items-center gap-5">
            <TotalBox />
          </div>
        </Flex>
      </div>
      <Container
        size="100%"
        bg="white"
        px="40"
        py="40"
        mx="40"
        mb="40"
        className="rounded-xl shadow-md"
      >
        <div className="mb-7 flex items-start justify-between gap-3">
          <div className="flex items-start gap-5">
            <SelectCliente disabled={true} />
            <div>
              <TextInput
                disabled
                value={data?.preventivi?.agente?.name}
                label="Venditore"
                radius={12}
                styles={{ input: { height: 44 } }}
              />
            </div>
            {filterActiveNotifications && (
              <div>
                <span className="text-sm font-medium">Notifiche</span>
                <List
                  spacing="xxs"
                  size="sm"
                  center
                  icon={<IconAlertCircle className="text-slate-900" />}
                >
                  {filterActiveNotifications.map((notification) => (
                    <List.Item key={notification._id}>
                      <div className="flex items-center gap-3">
                        <span className="font-semibold">
                          {transformTimestampToLocaleDateAndTime(
                            notification.alert_at,
                            true,
                            true,
                          )}
                        </span>
                        <span>{notification.text}</span>
                      </div>
                    </List.Item>
                  ))}
                </List>
              </div>
            )}
          </div>
          <div>
            <span className="block text-sm font-medium">Temperatura</span>
            <div className="flex h-11 items-center gap-3 rounded-xl bg-slate-200 px-3">
              <span
                //@ts-ignore
                className={`h-3 w-3 rounded-full ${getColorFromTemperature(temperatura)}`}
              ></span>
              <span>{temperatura}</span>
            </div>
          </div>
        </div>

        <Step3
          veicolo={veicolo}
          optionals={optionals}
          accessori={accessori}
          servizi={servizi}
          permuteTotal={permuteTotal}
          permute={permute}
          isReadOnly={true}
          sconti={sconti}
          scontoGrandTotal={scontoGrandTotal}
        />
      </Container>
    </>
  )
}

export default ViewPreventivo
