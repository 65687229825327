import { useGetListiniForModelloQuery } from "../../../features/api/apiSlice";
import { Select } from "@mantine/core";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  selectListino,
  selectModel,
  setListino,
} from "../../../features/preventivo/preventivoSlice";
import { formatListino } from "../../../utils/formatters";

const SelectListini = () => {
  const dispatch = useAppDispatch();
  const model = useAppSelector(selectModel);
  const disabled = !model;
  const { data } = useGetListiniForModelloQuery(
    { model: model },
    { skip: disabled },
  );
  const dataForSelect =
    data?.listini.map((value: any) => ({
      value,
      label: formatListino(value),
    })) || [];

  const currentListino = useAppSelector(selectListino);

  return (
    <Select
      label="Listino"
      placeholder="Seleziona il listino"
      value={currentListino ? currentListino : null}
      onChange={(sel) => sel && dispatch(setListino(sel))}
      disabled={disabled}
      data={dataForSelect}
      radius={12}
      styles={{ input: { height: 44 } }}
    />
  );
};

export default SelectListini;
