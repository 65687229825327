import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface UiState {
  isSidebarCollapsed: boolean;
  activeStep: number;
  isIvaShown: boolean;
}

const initialState: UiState = {
  isSidebarCollapsed: true,
  activeStep: 1,
  isIvaShown: false,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setSidebarCollapsed: (state) => {
      state.isSidebarCollapsed = !state.isSidebarCollapsed;
    },
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload;
    },
    setIvaShown: (state) => {
      state.isIvaShown = !state.isIvaShown;
    },
    reset: (state) => ({
      ...state,
      activeStep: 1,
    }),
  },
});

export const { setSidebarCollapsed, setActiveStep, setIvaShown, reset } =
  uiSlice.actions;

export const selectIsSidebarCollapsed = (state: RootState) =>
  state.ui.isSidebarCollapsed;
export const selectActiveStep = (state: RootState) => state.ui.activeStep;
export const selectIvaShown = (state: RootState) => state.ui.isIvaShown;

export default uiSlice.reducer;
