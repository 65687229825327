import { createApi, retry } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import settings from "../../settings.json";
import {
  queryAnagrafiche,
  mutationAnagrafica,
  AnagraficaQuery,
  Anagrafica,
  GetAnagraficheResponse,
  deleteAnagrafiche,
} from "../../queries/anagrafica";

import {
  queryVeicoli,
  VeicolisQuery,
  GetVeicolieResponse,
  queryOptionals,
  queryListiniPerModello,
  OptionalsQuery,
  GetOptionalResponse,
  GetListiniResponse,
  ListiniPerModelloQuery,
  GetEnginesResponse,
  EnginesPerModelloListinoQuery,
  queryEnginesPerModelloListino,
} from "../../queries/veicoli";
import {
  GetPreventiviResponse,
  GetPreventivoResponse,
  PreventiviQuery,
  Preventivo,
  PreventivoQuery,
  deletePreventivi,
  mutationPreventivo,
  queryPreventivi,
  queryPreventivo,
} from "../../queries/preventivi";
import {
  NotificationsQuery,
  NotificationsResponse,
  mutationNotification,
  queryNotifications,
  Notification,
} from "../../queries/notification";
import { UsersQuery, UsersResponse, queryUsers } from "../../queries/users";
import { getNewTokenWithRefresh } from "../../app/store";

const { appId, baseUrl } = settings;

export const apiSlice = createApi({
  baseQuery: retry(
    graphqlRequestBaseQuery({
      url: `${baseUrl}/api/client/v2.0/app/${appId}/graphql`,
      prepareHeaders: async (headers, { getState }) => {
        //@ts-ignore
        let { token } = getState().settings;
        const refreshToken = localStorage.getItem("refreshToken") || "";
        const tokenTS = +(localStorage.getItem("tokenTS") || "0");

        const _now = Date.now();
        const _elapsedMinutes = (_now - tokenTS) / 1000 / 60;

        if (_elapsedMinutes > 1) {
          // Refresh token
          token = await getNewTokenWithRefresh(refreshToken);
        }

        if (token) {
          headers.set("authorization", `Bearer ${token}`);
        } else {
          window.location.href = "/login";
        }
        return headers;
      },
      customErrors(args) {
        console.log(args);
        window.location.href = "/login";
      },
    }),
    {
      maxRetries: 2,
    },
  ),
  tagTypes: [
    "Anagrafica",
    "Veicolo",
    "Optionals",
    "Listini",
    "Preventivi",
    "Engines",
    "Notifications",
    "Testi",
  ],
  endpoints: (builder) => ({
    getListiniForModello: builder.query<
      GetListiniResponse,
      ListiniPerModelloQuery
    >({
      query: queryListiniPerModello,
      transformResponse: (response: { listini_distinct: any }, meta, arg) => {
        return response.listini_distinct;
      },
      providesTags: ["Listini"],
    }),
    getEnginesPerModelloListino: builder.query<
      GetEnginesResponse,
      EnginesPerModelloListinoQuery
    >({
      query: queryEnginesPerModelloListino,
      transformResponse: (response: { engines_distinct: any }, meta, arg) => {
        return response.engines_distinct;
      },
      providesTags: ["Engines"],
    }),
    getAnagrafiche: builder.query<GetAnagraficheResponse, AnagraficaQuery>({
      query: queryAnagrafiche,
      providesTags: ["Anagrafica"],
    }),
    putAnagrafica: builder.mutation<Anagrafica, Anagrafica>({
      query: mutationAnagrafica,
      invalidatesTags: ["Anagrafica"],
    }),
    deleteAnagrafiche: builder.mutation<null, string[]>({
      query: deleteAnagrafiche,
      invalidatesTags: ["Anagrafica"],
    }),
    getVeicoli: builder.query<GetVeicolieResponse, VeicolisQuery>({
      query: queryVeicoli,
      providesTags: ["Veicolo"],
    }),
    getOptionals: builder.query<GetOptionalResponse, OptionalsQuery>({
      query: queryOptionals,
      providesTags: ["Optionals"],
    }),
    getPreventivi: builder.query<GetPreventiviResponse, PreventiviQuery>({
      query: queryPreventivi,
      providesTags: ["Preventivi"],
    }),
    getPreventivo: builder.query<GetPreventivoResponse, PreventivoQuery>({
      query: queryPreventivo,
    }),
    putPreventivo: builder.mutation<Preventivo, Preventivo>({
      query: mutationPreventivo,
      invalidatesTags: ["Preventivi"],
    }),
    deletePreventivi: builder.mutation<null, string[]>({
      query: deletePreventivi,
      invalidatesTags: ["Preventivi"],
    }),
    getNotifications: builder.query<NotificationsResponse, NotificationsQuery>({
      query: queryNotifications,
      providesTags: ["Notifications"],
    }),
    putNotification: builder.mutation<Notification, Notification>({
      query: mutationNotification,
      invalidatesTags: ["Notifications"],
    }),
    getUsers: builder.query<UsersResponse, UsersQuery>({
      query: queryUsers,
    }),
  }),
});

export const {
  useGetAnagraficheQuery,
  usePutAnagraficaMutation,
  useDeleteAnagraficheMutation,
  useDeletePreventiviMutation,
  useGetVeicoliQuery,
  useGetOptionalsQuery,
  useLazyGetOptionalsQuery,
  useGetListiniForModelloQuery,
  useGetPreventiviQuery,
  useGetPreventivoQuery,
  useLazyGetPreventivoQuery,
  usePutPreventivoMutation,
  useGetEnginesPerModelloListinoQuery,
  useGetNotificationsQuery,
  usePutNotificationMutation,
  useGetUsersQuery,
} = apiSlice;
