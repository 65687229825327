import { IconTrash } from "@tabler/icons-react"
import DiscountForm from "../../../../components/Preventivo/PreventivoInputs/DiscountForm"
import InputAnticipo from "../../../../components/Preventivo/PreventivoInputs/InputAnticipo"
import PermutaForm from "../../../../components/Preventivo/PreventivoInputs/PermutaForm"
import OptionsSummary from "../../../../components/Preventivo/Summaries/OptionsSummary"
import VeicoloSummary from "../../../../components/Preventivo/Summaries/VeicoloSummary"
import SummaryBox from "../../../../components/Preventivo/SummaryBox/SummaryBox"
import SummaryVerticalBox from "../../../../components/Preventivo/SummaryVerticalBox/SummaryVerticalBox"
import { Button, NumberFormatter } from "@mantine/core"
import SaleDate from "../../../../components/Preventivo/PreventivoInputs/SaleDate"
import DeliveryDate from "../../../../components/Preventivo/PreventivoInputs/DeliveryDate"
import SelectPaymentMethod from "../../../../components/Preventivo/PreventivoInputs/SelectPaymentMethod"
import TextAreaNotes from "../../../../components/Preventivo/PreventivoInputs/TextAreaNotes"
import { EOptionType, Option, Veicolo } from "../../../../queries/veicoli"
import { Permuta, Sconto } from "../../../../queries/preventivi"
import { useAppDispatch } from "../../../../app/hooks"
import {
  removePermuta,
  removeSconto,
} from "../../../../features/preventivo/preventivoSlice"
import { convertDiscountType } from "../../../../utils/utils"
import {
  deleteModalProps,
  onModalDelete,
} from "../../../../components/Modal/ModalDelete/ModalDelete"
import { modals } from "@mantine/modals"

export interface Step3Props {
  veicolo: Veicolo | undefined
  optionals: Option[]
  accessori: Option[]
  servizi: Option[]
  permuteTotal: number
  scontoGrandTotal: number
  permute: Permuta[]
  isReadOnly?: boolean
  sconti: Sconto[]
}

const Step3 = ({
  veicolo,
  optionals,
  accessori,
  servizi,
  permuteTotal,
  permute,
  isReadOnly,
  sconti,
  scontoGrandTotal,
}: Step3Props) => {
  const dispatch = useAppDispatch()

  return (
    <>
      {veicolo && <VeicoloSummary veicolo={veicolo} />}

      <OptionsSummary
        type={EOptionType.OPTIONALS}
        items={optionals}
        label={"Riepilogo optionals "}
      />
      <OptionsSummary
        type={EOptionType.ACCESSORI}
        items={accessori}
        label={"Riepilogo accessori"}
      />
      <OptionsSummary
        type={EOptionType.SERVIZI}
        items={servizi}
        label={"Riepilogo servizi"}
      />

      <SummaryBox label="Permuta">
        {permute.map((permuta, index) => (
          <div
            className="relative mb-4 rounded-xl bg-slate-100 px-4 py-2 border"
            key={index}
          >
            <div className="flex items-end justify-between gap-2">
              <div>
                <div className="flex items-center gap-2">
                  <span className="text-sm uppercase">{permuta.title}</span>
                  <span className="text-xs block uppercase text-slate-600">
                    {permuta.targa}
                  </span>
                </div>

                <div className="flex items-center gap-2 mb-2">
                  <span className="text-xs uppercase text-slate-600">
                    <strong>KM: </strong>
                    {permuta.km}
                  </span>
                  <span className="text-xs block uppercase text-slate-600">
                    <strong>Anno: </strong>
                    {permuta.year}
                  </span>
                </div>

                <p className="text-sm font-light text-slate-600">
                  <strong className="text-xs uppercase font-bold">Note:</strong>{" "}
                  {permuta.description}
                </p>
              </div>
              <div className="flex items-center gap-5">
                {!isReadOnly && (
                  <Button
                    variant="transparent"
                    p={0}
                    onClick={() =>
                      modals.openConfirmModal({
                        ...deleteModalProps("permuta", "permute", [""]),
                        onConfirm: () => dispatch(removePermuta(permuta)),
                      })
                    }
                  >
                    <IconTrash className="h-5 w-5" />
                  </Button>
                )}
                <span className="font-semibold bg-slate-600 text-sm text-white rounded-full px-2">
                  <NumberFormatter
                    thousandSeparator="."
                    decimalScale={2}
                    decimalSeparator=","
                    value={permuta.price.toFixed(2)}
                  />{" "}
                  €
                </span>
              </div>
            </div>
          </div>
        ))}

        {!isReadOnly && <PermutaForm />}
      </SummaryBox>
      <SummaryBox label="Sconti">
        {sconti &&
          sconti.length > 0 &&
          sconti?.map((sconto, index) => {
            return (
              <div
                className="mb-4 flex items-start justify-between rounded-xl bg-slate-100 px-4 py-2 border"
                key={index}
              >
                <div>
                  <span className="text-sm block uppercase">
                    {convertDiscountType(sconto.type)}
                  </span>
                  <span className="text-sm">{sconto.label}</span>
                </div>
                <div className="flex items-center gap-5">
                  {!isReadOnly && !sconto.rif_pagamento && (
                    <Button
                      variant="transparent"
                      p={0}
                      onClick={() =>
                        modals.openConfirmModal({
                          ...deleteModalProps("sconto", "sconti", [""]),
                          onConfirm: () => dispatch(removeSconto(sconto)),
                        })
                      }
                    >
                      <IconTrash className="w-5 h-5" />
                    </Button>
                  )}
                  {sconto.rif_pagamento && (
                    <span className="text-xs uppercase font-semibold text-slate-600">
                      {sconto.rif_pagamento}
                    </span>
                  )}
                  <span className="font-semibold bg-slate-600 text-sm text-white rounded-full px-2">
                    <NumberFormatter
                      thousandSeparator="."
                      decimalScale={2}
                      decimalSeparator=","
                      value={sconto.price.toFixed(2)}
                    />{" "}
                    €
                  </span>
                </div>
              </div>
            )
          })}
        {!isReadOnly && <DiscountForm />}{" "}
      </SummaryBox>
      <div className="mb-7 grid grid-cols-2 gap-5">
        <SummaryVerticalBox label="Anticipo">
          <InputAnticipo isReadOnly={isReadOnly || false} />
        </SummaryVerticalBox>
        <SaleDate isReadOnly={isReadOnly || false} />
        <DeliveryDate isReadOnly={isReadOnly || false} />
        <SelectPaymentMethod isReadOnly={isReadOnly || false} />
      </div>
      <TextAreaNotes />
    </>
  )
}

export default Step3
