import { Select } from "@mantine/core";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectModelli } from "../../../features/settings/settingsSlice";
import {
  selectModel,
  setModel,
} from "../../../features/preventivo/preventivoSlice";

const SelectModello = () => {
  const dispatch = useAppDispatch();
  const modelliList = useAppSelector(selectModelli);
  const currentModel = useAppSelector(selectModel);

  return (
    <Select
      label="Modello"
      placeholder="Seleziona il modello"
      value={currentModel ? currentModel : null}
      data={modelliList}
      onChange={(sel) => dispatch(setModel(sel || ""))}
      radius={12}
      styles={{ input: { height: 44 } }}
    />
  );
};

export default SelectModello;
