import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { EOptionType, Option, Veicolo } from "../../queries/veicoli";
import { Anagrafica } from "../../queries/anagrafica";
import {
  EScontoType,
  PAYMENTS_TYPES,
  Permuta,
  Preventivo,
  Sconto,
} from "../../queries/preventivi";
import { EEngines } from "../settings/settingsSlice";
import { TS4Display } from "../../utils/formatters";

export enum EPreventivoStatus {
  DRAFT = "draft",
  DRAFT_MODIFIED = "draft_modified",
  DRAFT_SAVED = "draft_saved",
  PRINTED = "printed",
  SENT = "sent",
}

export interface PreventivoState extends Preventivo {}

const initialState: PreventivoState = {
  options: [],
  permute: [],
  sconti: [],
  anticipo: 0,
  notes: "",
  show_iva: false,
  status: EPreventivoStatus.DRAFT,
  started_at: Date.now(),
  first_saved_at: 0,
  sconto_temp: {
    label: "Sconto",
    price: 0,
    type: EScontoType.PREZZO_FINALE,
  },
  temperatura: "Tiepida",
  pagamento: "Bonifico Bancario",
  iva: 22,
};

interface SetOptionAction {
  options: Option[];
  type: EOptionType;
}

export const preventivoSlice = createSlice({
  name: "preventivo",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setTemperatura: (state, action: PayloadAction<string>) => {
      state.temperatura = action.payload;
    },
    setModel: (state, action: PayloadAction<string>) => {
      state.veicolo = {
        model: action.payload,
      };
      state.options = [];
    },
    setListino: (state, action: PayloadAction<string>) => {
      state.veicolo = {
        //@ts-ignore
        model: state.veicolo.model,
        listino: action.payload || "",
      };
      state.options = [];
    },
    setEngine: (state, action: PayloadAction<EEngines>) => {
      state.veicolo = {
        //@ts-ignore
        model: state.veicolo.model,
        //@ts-ignore
        listino: state.veicolo.listino,
        engine: action.payload,
      };
      state.options = [];
    },
    setVeicolo: (state, action: PayloadAction<Veicolo>) => {
      state.veicolo = {
        ...action.payload,
        engine: action.payload.engine || state.veicolo?.engine,
      };
      state.options = [];
    },
    setCliente: (state, action: PayloadAction<Anagrafica>) => {
      state.cliente = action.payload;
    },
    setModello: (state, action: PayloadAction<string>) => {
      state.modello = action.payload;
    },
    setOggetto: (state, action: PayloadAction<string>) => {
      state.oggetto = action.payload;
    },
    setMotore: (state, action: PayloadAction<string>) => {
      state.motore = action.payload;
    },
    setDimensioni: (state, action: PayloadAction<string>) => {
      state.dimensioni = action.payload;
    },
    setDotazioniGuida: (state, action: PayloadAction<string>) => {
      state.dotazioni_guida = action.payload;
    },
    setDotazioniSicurezza: (state, action: PayloadAction<string>) => {
      state.dotazioni_sicurezza = action.payload;
    },
    setDotazioniInterne: (state, action: PayloadAction<string>) => {
      state.dotazioni_interne = action.payload;
    },
    setDotazioniTecnologia: (state, action: PayloadAction<string>) => {
      state.dotazioni_tecnologia = action.payload;
    },
    setCaratteristicheVersione: (state, action: PayloadAction<string>) => {
      state.caratteristiche_versione = action.payload;
    },
    setCorteseAttenzione: (state, action: PayloadAction<string>) => {
      if (state.cliente) {
        state.cliente.cortese_attenzione = action.payload;
      }
    },
    addPermuta: (state, action: PayloadAction<Permuta>) => {
      state.permute.push({
        ...action.payload,
        price: Math.round(action.payload.price * 100) / 100,
      });
    },
    addSconto: (state, action: PayloadAction<Sconto>) => {
      state.sconti.push(action.payload);
      state.sconto_temp = {
        label: "Sconto base",
        price: 0,
        type: EScontoType.VEICOLO,
      };
    },
    removeSconto: (state, action: PayloadAction<Sconto>) => {
      const _sconti = state.sconti.filter(
        ({ type, price }) =>
          `${type}${price}` !== `${action.payload.type}${action.payload.price}`,
      );
      state.sconti = _sconti;
    },
    removePermuta: (state, action: PayloadAction<Permuta>) => {
      const currentPermuteState = state.permute.filter(
        ({ title }) => title !== action.payload.title,
      );
      state.permute = currentPermuteState;
    },
    setOptions: (state, action: PayloadAction<SetOptionAction>) => {
      const _optionsToPreserve = state.options.filter(
        ({ cm_type, obbligatorio }) =>
          cm_type !== action.payload.type || "true" === obbligatorio,
      );

      state.options = [
        ..._optionsToPreserve,
        ...action.payload.options.map((opt) => ({
          ...opt,
          price: Math.round(opt.price * 100) / 100,
        })),
      ];
    },
    setScontoTemp: (state, action: PayloadAction<Sconto>) => {
      state.sconto_temp = {
        ...action.payload,
        price: Math.round(action.payload.price * 100) / 100,
      };
    },
    setAnticipo: (state, action: PayloadAction<number | string>) => {
      state.anticipo = Math.round(+action.payload * 100) / 100;
    },
    setModalitaPagamento: (state, action: PayloadAction<string>) => {
      const _pagamento = PAYMENTS_TYPES.find(
        ({ label }) => label === action.payload,
      );

      state.sconti = [
        ...state.sconti.filter(
          ({ rif_pagamento }) => !rif_pagamento || rif_pagamento === "",
        ),
      ];
      state.options = [
        ...state.options.filter(
          ({ rif_pagamento }) => !rif_pagamento || rif_pagamento === "",
        ),
      ];

      if (Array.isArray(_pagamento?.sconti) && _pagamento.sconti.length > 0) {
        state.sconti = [...state.sconti, ..._pagamento.sconti];
      }

      if (Array.isArray(_pagamento?.servizi) && _pagamento.servizi.length > 0) {
        _pagamento.servizi.forEach((servizio) => {
          state.options.push(servizio as Option);
        });
      }

      state.pagamento = action.payload;
    },
    setNote: (state, action: PayloadAction<string>) => {
      state.notes = action.payload;
    },
    setIva: (state, action: PayloadAction<number>) => {
      state.iva = action.payload;
    },
    setInitialPreventivo: (state, action: PayloadAction<Preventivo>) => {
      Object.keys(action.payload).forEach((key) => {
        // qui non c'è modo di fare il cast di key a keyof Preventivo
        // quindi andiamo di buon vecchio vanilla js
        const value =
          key.includes("_at") || key.includes("date")
            ? // @ts-ignore
              TS4Display(+action.payload[key])
            : // @ts-ignore
              action.payload[key];

        // @ts-ignore
        state[key] = value;
      });
    },
    reset: () => initialState,
  },
});

export const {
  setTemperatura,
  setInitialPreventivo,
  setVeicolo,
  setListino,
  setEngine,
  addPermuta,
  addSconto,
  removeSconto,
  removePermuta,
  setOptions,
  setCliente,
  setModel,
  setModello,
  setOggetto,
  setMotore,
  setDimensioni,
  setDotazioniGuida,
  setDotazioniInterne,
  setDotazioniSicurezza,
  setDotazioniTecnologia,
  setCaratteristicheVersione,
  setCorteseAttenzione,
  setScontoTemp,
  setAnticipo,
  setModalitaPagamento,
  setNote,
  setIva,
  reset,
} = preventivoSlice.actions;

export const selectTemperatura = (state: RootState) =>
  state.preventivo.temperatura;
export const selectListino = (state: RootState) =>
  state.preventivo.veicolo?.listino;
export const selectCliente = (state: RootState) => state.preventivo.cliente;
export const selectAgente = (state: RootState) => state.preventivo.agente;
export const selectVeicolo = (state: RootState) => state.preventivo.veicolo;
export const selectEngine = (state: RootState) =>
  state.preventivo.veicolo?.engine;
export const selectModel = (state: RootState) =>
  state.preventivo.veicolo?.model;
export const selectPermute = (state: RootState) => state.preventivo.permute;
export const selectSconti = (state: RootState) => state.preventivo.sconti;
export const selectOptions = (state: RootState) => state.preventivo.options;
export const selectOptionals = createSelector([selectOptions], (options) => {
  return options.filter(({ cm_type }) => cm_type === EOptionType.OPTIONALS);
});
export const selectAccessori = createSelector([selectOptions], (options) => {
  return options.filter(({ cm_type }) => cm_type === EOptionType.ACCESSORI);
});
export const selectServizi = createSelector([selectOptions], (options) => {
  return options.filter(({ cm_type }) => cm_type === EOptionType.SERVIZI);
});
export const selectFirstSavedAt = (state: RootState) =>
  state.preventivo.first_saved_at;
export const selectAnticipo = (state: RootState) => state.preventivo.anticipo;
export const selectNote = (state: RootState) => state.preventivo.notes;
export const selectIva = (state: RootState) => state.preventivo.iva;
export const selectModalitaPagamento = (state: RootState) =>
  state.preventivo.pagamento;
export const selectOggetto = (state: RootState) => state.preventivo.oggetto;
export const selectModello = (state: RootState) => state.preventivo.modello;
export const selectMotore = (state: RootState) => state.preventivo.motore;
export const selectDimensioni = (state: RootState) =>
  state.preventivo.dimensioni;
export const selectDotazioniGuida = (state: RootState) =>
  state.preventivo.dotazioni_guida;
export const selectDotazioniSicurezza = (state: RootState) =>
  state.preventivo.dotazioni_sicurezza;
export const selectDotazioniInterne = (state: RootState) =>
  state.preventivo.dotazioni_interne;
export const selectDotazioniTecnologia = (state: RootState) =>
  state.preventivo.dotazioni_tecnologia;
export const selectCaratteristicheVersione = (state: RootState) =>
  state.preventivo.caratteristiche_versione;
export const selectCorteseAttenzione = (state: RootState) =>
  state.preventivo.cliente?.cortese_attenzione;
/*
export const selectFrontDate = (state: RootState) => {
  if (state.preventivo.front_date) {
    return state.preventivo.front_date;
  }
  switch (state.preventivo.status) {
    case EPreventivoStatus.DRAFT:
      // return today as day/month/year string
      const today = new Date();
      return `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;
    default:
      return "";
  }
};
*/
export const selectScontoTemp = (state: RootState) =>
  state.preventivo.sconto_temp;

export const selectTotalBeforeDiscount = createSelector(
  [selectOptions, selectVeicolo],
  (options, veicolo) => {
    let total = 0;

    total += veicolo?.price || 0;

    options
      .filter(({ cm_type }) => cm_type !== EOptionType.SERVIZI)
      .forEach((opt) => {
        total += opt.price;
      });

    return total;
  },
);

export const selectAccessoriValues = createSelector(
  [selectAccessori, selectSconti, selectScontoTemp],
  (accessori, sconti, scontoTemp) => {
    const accessoriTotalPrice =
      accessori.length > 0
        ? accessori
            .map(({ price }) => price)
            .reduce((acc, price) => acc + price)
        : 0;

    const allSconti = [...sconti, scontoTemp];
    const accessoriTotalDiscount =
      allSconti.length > 0
        ? allSconti
            .filter(({ type }) => type === EScontoType.ACCESSORI)
            .reduce((acc, { price }) => acc + price, 0)
        : 0;

    const margine = accessoriTotalPrice * 0.3 - accessoriTotalDiscount;

    return [accessoriTotalPrice, accessoriTotalDiscount, margine];
  },
);

export const selectOptionalsValues = createSelector(
  [selectOptionals, selectSconti, selectScontoTemp],
  (optionals, sconti, scontoTemp) => {
    const optionalsTotalPrice =
      optionals.length > 0
        ? optionals
            .map(({ price }) => price)
            .reduce((acc, price) => acc + price)
        : 0;

    const allSconti = [...sconti, scontoTemp];
    const optionalsTotalDiscount =
      allSconti.length > 0
        ? allSconti
            .filter(({ type }) => type === EScontoType.VEICOLO_OPTIONALS)
            .reduce((acc, { price }) => acc + price, 0)
        : 0;

    const margine = optionalsTotalPrice * 0.2 - optionalsTotalDiscount;

    return [optionalsTotalPrice, optionalsTotalDiscount, margine];
  },
);

export const selectServiziValues = createSelector(
  [selectServizi, selectSconti, selectScontoTemp],
  (servizi, sconti, scontoTemp) => {
    const serviziTotalPrice =
      servizi.length > 0
        ? servizi.map(({ price }) => price).reduce((acc, price) => acc + price)
        : 0;

    const allSconti = [...sconti, scontoTemp];
    const serviziTotalDiscount =
      allSconti.length > 0
        ? allSconti
            .filter(({ type }) => type === EScontoType.SERVIZI)
            .reduce((acc, { price }) => acc + price, 0)
        : 0;

    const margine = serviziTotalPrice * 0.2 - serviziTotalDiscount;

    return [serviziTotalPrice, serviziTotalDiscount, margine];
  },
);

export const selectFerroValues = createSelector(
  [selectVeicolo, selectSconti, selectScontoTemp],
  (veicolo, sconti, scontoTemp) => {
    const veicoloTotalPrice = veicolo?.price || 0;

    const allSconti = [...sconti, scontoTemp];
    const veicoloTotalDiscount =
      allSconti.length > 0
        ? allSconti
            .filter(({ type }) => type === EScontoType.VEICOLO)
            .reduce((acc, { price }) => acc + price, 0)
        : 0;
    const margine = veicoloTotalPrice * 0.2 - veicoloTotalDiscount;

    return [veicoloTotalPrice, veicoloTotalDiscount, margine];
  },
);

export const selectScontoGrandTotal = createSelector(
  [
    selectAccessoriValues,
    selectOptionalsValues,
    selectServiziValues,
    selectFerroValues,
    selectSconti,
    selectScontoTemp,
  ],
  (
    accessoriValues,
    optionalsValues,
    serviziValues,
    ferroValues,
    sconti,
    scontoTemp,
  ) => {
    const scontiFinali = [...sconti, scontoTemp]
      .filter(({ type }) => type === EScontoType.PREZZO_FINALE)
      .reduce((acc, { price }) => acc + price, 0);

    return (
      accessoriValues[1] +
      optionalsValues[1] +
      serviziValues[1] +
      ferroValues[1] +
      scontiFinali
    );
  },
);

export const selectTotalPermute = createSelector([selectPermute], (permute) => {
  return permute.reduce((acc, { price }) => acc + price, 0);
});

export const selectTotalVariations = createSelector(
  [selectScontoGrandTotal],
  (scontoGrandTotal) => {
    return scontoGrandTotal;
  },
);

export const selectPriceFinal = createSelector(
  [selectTotalBeforeDiscount, selectTotalVariations, selectServiziValues],
  (totalBeforeDiscount, totalVariations, serviziValues) => {
    return totalBeforeDiscount - totalVariations + serviziValues[0];
  },
);

export const selectMargine = createSelector(
  [
    selectAccessoriValues,
    selectOptionalsValues,
    selectServiziValues,
    selectFerroValues,
  ],
  (accessoriValues, optionalsValues, serviziValues, ferroValues) => {
    return (
      accessoriValues[2] +
      optionalsValues[2] +
      serviziValues[2] +
      ferroValues[2]
    );
  },
);

export default preventivoSlice.reducer;
