import { NumberFormatter } from "@mantine/core";
import { useAppSelector } from "../../../app/hooks";
import {
  selectTotalBeforeDiscount,
  selectPriceFinal,
} from "../../../features/preventivo/preventivoSlice";

const TotalBox = () => {
  const totalBeforeDiscount = useAppSelector(selectTotalBeforeDiscount);
  const priceFinal = useAppSelector(selectPriceFinal);
  const bigShow = priceFinal;

  return (
    <div className="relative rounded-xl shadow-md">
      <div className="relative z-20 flex items-center">
        <div className="flex h-14 min-w-60 flex-row-reverse items-center justify-center gap-5 rounded-xl bg-white px-3">
          <div className="flex flex-1 items-center justify-center gap-2">
            <span className="text-sm text-slate-500 ">TOT.</span>
            <span className="text-xl font-semibold text-black">
              <NumberFormatter
                value={bigShow.toFixed(2)}
                decimalScale={2}
                thousandSeparator="."
                decimalSeparator=","
              />
            </span>
            €
          </div>
          <span className="flex-1 rounded-md bg-slate-600 px-1 text-xs font-semibold text-white">
            LISTINO{" "}
            <NumberFormatter
              value={totalBeforeDiscount.toFixed(2)}
              decimalScale={2}
              thousandSeparator="."
              decimalSeparator=","
            />{" "}
            €
          </span>
        </div>
      </div>
    </div>
  );
};

export default TotalBox;
