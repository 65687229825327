import { Button, NumberInput, Select, TextInput } from "@mantine/core";
import { IconCirclePlus } from "@tabler/icons-react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  addSconto,
  selectAccessoriValues,
  selectFerroValues,
  selectOptionalsValues,
  selectPriceFinal,
  selectScontoTemp,
  selectServiziValues,
  setScontoTemp,
} from "../../../features/preventivo/preventivoSlice";
import { useState } from "react";
import { EScontoType } from "../../../queries/preventivi";

const DiscountForm = () => {
  const dispatch = useAppDispatch();
  const priceFinal = useAppSelector(selectPriceFinal);
  const scontoTemp = useAppSelector(selectScontoTemp);
  const [accessoriTotal] = useAppSelector(selectAccessoriValues);
  const [serviziTotal] = useAppSelector(selectServiziValues);
  const [optionalsTotal] = useAppSelector(selectOptionalsValues);
  const [ferroTotal] = useAppSelector(selectFerroValues);
  const [percentage, setPercentage] = useState(0);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    const _v = Object.keys(EScontoType).find(
      (key) => (EScontoType as any)[key] === scontoTemp.type,
    );

    // controlla che lo sconto non superi il valore totale della tipologia
    let checkvalue = 0;
    switch (scontoTemp.type) {
      case EScontoType.ACCESSORI:
        checkvalue = accessoriTotal;
        break;
      case EScontoType.SERVIZI:
        checkvalue = serviziTotal;
        break;
      case EScontoType.VEICOLO_OPTIONALS:
        checkvalue = optionalsTotal;
        break;
      case EScontoType.VEICOLO:
        checkvalue = ferroTotal;
        break;
      case EScontoType.PREZZO_FINALE:
        checkvalue = priceFinal;
        break;
    }

    if (scontoTemp.price > checkvalue) {
      //TODO: Aggiungere modale con errore e rimuovere l'alert scrauso (usare stesso messaggio)
      window.alert("Lo sconto impostato è troppo alto.");
      dispatch(
        setScontoTemp({
          price: 0,
          type: scontoTemp.type,
          label: scontoTemp.label,
        }),
      );
      scontoTemp.price = 0;
      return;
    }

    dispatch(
      addSconto({
        ...scontoTemp,
        label: scontoTemp.label || `Sconto ${_v}`,
      }),
    );
    setPercentage(0);
  };

  const handleChangeDescription = (value: string) => {
    dispatch(
      setScontoTemp({
        price: scontoTemp.price,
        type: scontoTemp.type,
        label: value,
      }),
    );
  };

  const handleChangePercentage = (value: string | number) => {
    const currentValue = +value;
    setPercentage(currentValue);
    let price = 0;

    switch (scontoTemp.type) {
      case EScontoType.ACCESSORI:
        price = (accessoriTotal * currentValue) / 100;
        break;
      case EScontoType.SERVIZI:
        price = (serviziTotal * currentValue) / 100;
        break;
      case EScontoType.VEICOLO_OPTIONALS:
        price = (optionalsTotal * currentValue) / 100;
        break;
      case EScontoType.VEICOLO:
        price = (ferroTotal * currentValue) / 100;
        break;
      case EScontoType.PREZZO_FINALE:
        price = priceFinal / 100;
        break;
    }

    dispatch(
      setScontoTemp({
        price,
        type: scontoTemp.type,
        label: scontoTemp.label,
      }),
    );
  };

  const handleChangeDiscount = (value: string | number) => {
    const currentValue = +value;

    let percentageValue = 0;

    switch (scontoTemp.type) {
      case EScontoType.ACCESSORI:
        percentageValue = (currentValue / accessoriTotal) * 100;
        break;
      case EScontoType.SERVIZI:
        percentageValue = (currentValue / serviziTotal) * 100;
        break;
      case EScontoType.VEICOLO_OPTIONALS:
        percentageValue = (currentValue / optionalsTotal) * 100;
        break;
      case EScontoType.VEICOLO:
        percentageValue = (currentValue / ferroTotal) * 100;
        break;
      case EScontoType.PREZZO_FINALE:
        percentageValue = (currentValue / priceFinal) * 100;
        break;
    }

    setPercentage(percentageValue);

    dispatch(
      setScontoTemp({
        price: currentValue,
        type: scontoTemp.type,
        label: scontoTemp.label,
      }),
    );
  };

  function handleChangeType(value: EScontoType): void {
    setPercentage(0);
    dispatch(
      setScontoTemp({
        price: 0,
        type: value,
        label: scontoTemp.label,
      }),
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-5 flex justify-between gap-2">
        <Select
          label="Tipologia"
          placeholder="Seleziona la tipologia"
          // @ts-ignore
          onChange={handleChangeType}
          value={scontoTemp.type}
          data={Object.keys(EScontoType).map((key) => ({
            value: EScontoType[key as keyof typeof EScontoType],
            label: `${key.charAt(0).toLocaleUpperCase()}${key.slice(1).toUpperCase().replace(/_/g, " ")}`,
          }))}
          w={"100%"}
          radius={12}
          styles={{ input: { height: 44 } }}
        />
        <TextInput
          label="Descrizione"
          value={scontoTemp.label}
          onChange={(e) => handleChangeDescription(e.target.value)}
          w={"100%"}
          styles={{
            input: {
              height: 44,
              borderRadius: 12,
            },
          }}
        />
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-end gap-2">
          <span className="block text-sm font-medium">Valore</span>
          <div className="flex gap-2">
            <NumberInput
              min={0}
              max={100}
              decimalScale={2}
              value={percentage}
              onChange={handleChangePercentage}
              allowNegative={false}
              rightSection={<span>%</span>}
              decimalSeparator=","
              styles={{
                input: {
                  width: 70,
                  height: 44,
                  borderRadius: 12,
                },
              }}
            />
            <NumberInput
              min={0}
              value={scontoTemp.price}
              onChange={handleChangeDiscount}
              allowNegative={false}
              decimalScale={2}
              rightSection={<span>€</span>}
              decimalSeparator=","
              styles={{
                input: {
                  width: 100,
                  height: 44,
                  borderRadius: 12,
                },
              }}
            />
          </div>
        </div>
        <Button
          disabled={+scontoTemp.price === 0}
          type="submit"
          radius={100}
          leftSection={<IconCirclePlus />}
        >
          Aggiungi
        </Button>
      </div>
    </form>
  );
};

export default DiscountForm;
