import { List, NumberFormatter } from "@mantine/core"
import SummaryBox from "../SummaryBox/SummaryBox"
import { IconCircleCheck } from "@tabler/icons-react"
import { useAppSelector } from "../../../app/hooks"
import {
  selectAccessoriValues,
  selectOptionalsValues,
  selectServiziValues,
} from "../../../features/preventivo/preventivoSlice"
import { EOptionType, Option } from "../../../queries/veicoli"

const OptionalsSummary = ({
  items,
  type,
  label,
}: {
  items: Option[]
  type: EOptionType
  label: string
}) => {
  const totalItems = items ? items.length : 0
  let [totalPrice, totalDiscount] = [0, 0]

  switch (type) {
    case EOptionType.ACCESSORI:
      ;[totalPrice, totalDiscount] = useAppSelector(selectAccessoriValues)
      break
    case EOptionType.OPTIONALS:
      ;[totalPrice, totalDiscount] = useAppSelector(selectOptionalsValues)
      break
    case EOptionType.SERVIZI:
      ;[totalPrice, totalDiscount] = useAppSelector(selectServiziValues)
      break
  }

  return (
    totalItems > 0 && (
      <SummaryBox
        label={label}
        totalItems={totalItems}
        //totalPrice={totalPrice}
        //totalDiscount={totalDiscount}
        withToggle
      >
        <div className="summary__list">
          <List
            spacing="xxs"
            size="sm"
            center
            icon={<IconCircleCheck className="text-slate-900" />}
          >
            {items?.map((optional) => (
              <List.Item key={optional.id}>
                {optional.label}
                {" ("}
                <NumberFormatter
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  value={optional.price}
                />
                €{")"}
              </List.Item>
            ))}
          </List>
        </div>
      </SummaryBox>
    )
  )
}

export default OptionalsSummary
