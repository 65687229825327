import { Button, Container } from "@mantine/core";
import PreventiviTable from "../../../components/Table/PreventiviTable";
import { Link, useParams } from "react-router-dom";
import { IconFilePlus } from "@tabler/icons-react";

const PreventiviCliente = () => {
  const { customerId } = useParams();

  return (
    <>
      <Container size="100%" px="40" py="20">
        <Link to={`/preventivi/nuovo?id_cliente=${customerId}`}>
          <Button
            variant="outline"
            radius="xl"
            leftSection={<IconFilePlus size={18} />}
          >
            Nuovo preventivo
          </Button>
        </Link>
      </Container>
      <Container
        size="100%"
        className="mx-10 rounded-xl bg-transparent p-0 shadow-md"
      >
        <PreventiviTable />
      </Container>
    </>
  );
};

export default PreventiviCliente;
