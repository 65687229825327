import { DateInput } from "@mantine/dates";
import SummaryVerticalBox from "../SummaryVerticalBox/SummaryVerticalBox";
import { IconCalendar } from "@tabler/icons-react";
import { useAppSelector } from "../../../app/hooks";

const DeliveryDate = ({ isReadOnly }: { isReadOnly: boolean }) => {
  // const deliveryDateValue = useAppSelector((state) => state.preventivo);

  return (
    <SummaryVerticalBox label="Data consegna">
      <DateInput
        //defaultValue={new Date()}
        valueFormat="DD/MM/YYYY"
        leftSection={<IconCalendar />}
        className="w-full"
        disabled={isReadOnly}
        styles={{
          root: {},
          input: {
            width: "100%",
            backgroundColor: "#f1f5f9",
            border: "none",
            borderRadius: 12,
          },
        }}
        // value={value}
        // onChange={setValue}
      />
    </SummaryVerticalBox>
  );
};

export default DeliveryDate;
